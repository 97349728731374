/*
 * Tencent is pleased to support the open source community by making TMagicEditor available.
 *
 * Copyright (C) 2021 THL A29 Limited, a Tencent company.  All rights reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { IEventItemConfig, ServiceConfigure } from './dataSource';


export * from './dataSource';

export enum NodeType {
  CONTAINER = 'container',
  PAGE = 'page',
  ROOT = 'app',
}

export type Id = string | number;

export interface EventItemConfig {
  /** 被选中组件ID */
  to: Id | { id: Id; type: 'dataService' };
  /** 被选中组件名称 */
  name: string;
  /** 触发事件后执行被选中组件的方法 */
  method: string;
}

export type NodeAnimation = {
  autoPlay: boolean;
  pageLeaveReversePlay: boolean;
  method?: string;
  duration: number;
  delay: number;
  loop: number;
  custom?: object | Function;
}

export interface MComponent {
  /** 组件ID，默认为${type}_${number}}形式, 如：page_123 */
  id: Id;
  /** 组件类型 */
  type?: string;
  /** 组件显示名称 */
  version: string;
  /** 组件显示名称 */
  name?: string;
  /** 组件根Dom上的class */
  className?: string;
  /* 关联事件集合 */
  events?: IEventItemConfig[];
  /** 组件根Dom的style */
  style?: {
    [key: string]: any;
  };
  /** 动效 */
  animation?: NodeAnimation;

  /** 编辑器标识 */
  editorFlag?: {
    add?: boolean;
    showPanel?: boolean;
    drag?: boolean;
    resize?: boolean;
    drop?: {
      inner?: boolean;
      prev?: boolean;
      next?: boolean;
    };
  };

  [key: string]: any;
}

export interface MContainer extends MComponent {
  /** 容器类型，默认为'container' */
  type?: NodeType.CONTAINER | string;
  /** 容器子元素 */
  items: (MComponent | MContainer)[];
}

export interface MPage extends MContainer {
  /** 页面类型 */
  type: NodeType.PAGE;
}

export interface MPlugin {
  enable: boolean;
  type: string;
  version: string;
  plugin_id: string;
  [key: string]: any;
}

export interface MApp extends MComponent {
  /** App页面类型，app作为整个结构的根节点；有且只有一个 */
  type: NodeType.ROOT;
  /** */
  items: MPage[];
  /** 代码块 */
  codeBlocks?: CodeBlockDSL;
  /** 数据源 */
  dataServiceConfigure?: ServiceConfigure[];
  /** 插件 */
  plugins: Record<string, MPlugin>;
  /** 画布大小 */
  stageRect: {
    device: 'phone' | 'pad' | 'pc' | 'custom';
    width: number;
    height: number;
    customWidth: number;
    customHeight: number;
  };
  /** 应用类型 */
  pageType: PageTypeEnum;
  /** 区域类型 */
  businessCategory: number;
}

export interface CodeBlockDSL {
  [id: string]: CodeBlockContent;
}

export interface CodeBlockContent {
  /** 代码块名称 */
  name: string;
  /** 代码块内容 */
  content: any;
}
export interface PastePosition {
  left?: number;
  top?: number;
}

export type MNode = MComponent | MContainer | MPage | MApp;

// 应用类型
export enum PageTypeEnum {
  clientH5 = 1,
  business = 2,
}