import { ComponentPublicInstance, inject, onBeforeUnmount, onMounted, onUnmounted, ref, watchEffect } from 'vue';
import Core, { execAnimation } from "@tmagic/core";
import { MNode, MPage } from '@tmagic/schema';
import { Router } from 'vue-router';

export function runAnimation() {
  return {
    appCreated: (app: Core) => {
      const router = ((app as any).router as Router);
      router.afterEach((to, from) => {
        if (!from.name) return
        const page = app.pages.get(from.name as string)!;
        const { animation } = page.data;
        if (animation?.pageLeaveReversePlay) {
          to.meta.leaveDuration = (Number(animation?.duration) || 0) + (Number(animation?.delay || 0));
        }
      });
    },
    componentSetup: (app: Core, vm: ComponentPublicInstance, componentConfig: any) => {
      const page = ref(app.page!)
      onMounted(() => {
        const { animation, id } = (vm.$props as any).config as unknown as MNode;
        if (animation?.autoPlay) {
          const node = page.value.getNode(id);
          node && execAnimation(node);
        }
      });
      onBeforeUnmount(() => {
        const { animation, id } = (vm.$props as any).config as unknown as MNode;
        if (animation?.pageLeaveReversePlay) {
          const node = page.value.getNode(id);
          node && execAnimation(node, true);
        }
      });
    },
  }
}