import { computed, getCurrentInstance, inject, ref, watch } from "vue";
import Core from '@tmagic/core'
import { MNode } from "@tmagic/schema";

export default function useDisplay(props: {
  config: {
    id: any;
    display: boolean;
    [key: string]: any;
  };
}) {
  const visible = ref(true);
  watch(
    () => props.config.display,
    () => {
      visible.value = props.config.display !== false;
    },
    { immediate: true }
  );

  const app: Core = inject('app')!;
  const node = app.page?.getNode(props.config.id);

  watch(
    () => visible.value,
    () => {
      console.log(`组件${props.config.id}的可见性变为${visible.value}， 并广播事件`);
      if (visible.value) {
        app?.emit('display', node);
      } else {
        app?.emit('hidden', node);
      }
    }
  );

  const displayBySelect = ref(false);
  if (app?.mode === 'playground') {
    app?.on('editor:select', (info, path) => {
      if (path.find((node: MNode) => node.id === props.config.id)) {
        displayBySelect.value = true;
      } else {
        displayBySelect.value = false;
      }
    });
  }

  return {
    visible: computed(() => visible.value || displayBySelect.value),
    pureVisible: visible,
    show() {
      visible.value = true;
    },
    hide() {
      visible.value = false;
    },
  }
}