/*
 * Tencent is pleased to support the open source community by making TMagicEditor available.
 *
 * Copyright (C) 2021 THL A29 Limited, a Tencent company.  All rights reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import dayjs from 'dayjs';
import serialize from 'serialize-javascript';
import utc from 'dayjs/plugin/utc';
import qs from 'query-string';

import type { MNode } from '@tmagic/schema';
import { NodeType, PageTypeEnum } from '@tmagic/schema';

export * from './dom';


dayjs.extend(utc);

export const sleep = (ms: number): Promise<void> =>
  new Promise((resolve) => {
    const timer = setTimeout(() => {
      clearTimeout(timer);
      resolve();
    }, ms);
  });

export const datetimeFormatter = (
  v: string | Date,
  defaultValue = '-',
  format = 'YYYY-MM-DD HH:mm:ss',
): string | number => {
  if (v) {
    let time = null;
    if (['x', 'timestamp'].includes(format)) {
      time = dayjs(v).valueOf();
    } else if ((typeof v === 'string' && v.includes('Z')) || v.constructor === Date) {
      // UTC字符串时间或Date对象格式化为北京时间
      time = dayjs(v).utcOffset(8).format(format);
    } else {
      time = dayjs(v).format(format);
    }

    // 格式化为北京时间
    if (time !== 'Invalid Date') {
      return time;
    }
    return defaultValue;
  }
  return defaultValue;
};

// 驼峰转换横线
export const toLine = (name = '') => name.replace(/\B([A-Z])/g, '-$1').toLowerCase();

export const toHump = (name = ''): string => name.replace(/-(\w)/g, (all, letter) => letter.toUpperCase());

export const emptyFn = (): any => undefined;

/**
 * 通过id获取组件在应用的子孙路径
 * @param {number | string} id 组件id
 * @param {Array} data 要查找的根容器节点
 * @return {Array} 组件在data中的子孙路径
 */
export const getNodePath = (id: number | string, data: MNode[] = []): MNode[] => {
  const path: MNode[] = [];

  const get = function (id: number | string, data: MNode[]): MNode | null {
    if (!Array.isArray(data)) {
      return null;
    }

    for (let i = 0, l = data.length; i < l; i++) {
      const item: any = data[i];

      path.push(item);
      if (`${item.id}` === `${id}`) {
        return item;
      }

      if (item.items) {
        const node = get(id, item.items);
        if (node) {
          return node;
        }
      }

      path.pop();
    }

    return null;
  };

  get(id, data);

  return path;
};

export const filterXSS = (str: string) =>
  str.replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;').replace(/'/g, '&apos;');

export const getUrlParam = (param: string, url?: string) => {
  const u = url || location.href;
  const reg = new RegExp(`[?&#]${param}=([^&#]+)`, 'gi');

  const matches = u.match(reg);
  let strArr;
  if (matches && matches.length > 0) {
    strArr = matches[matches.length - 1].split('=');
    if (strArr && strArr.length > 1) {
      // 过滤XSS字符
      return filterXSS(strArr[1]);
    }
    return '';
  }
  return '';
};

export const isPop = (node: MNode): boolean => Boolean(node.type?.toLowerCase().endsWith('pop'));

export const isPage = (node: MNode | undefined): boolean => {
  if (!node) return false;
  return Boolean(node.type?.toLowerCase() === NodeType.PAGE);
};

export const isNumber = (value: string) => /^(-?\d+)(\.\d+)?$/.test(value);

export const getHost = (targetUrl: string) => targetUrl.match(/\/\/([^/]+)/)?.[1];

export const isSameDomain = (targetUrl = '', source = globalThis.location.host) => {
  const isHttpUrl = /^(http[s]?:)?\/\//.test(targetUrl);

  if (!isHttpUrl) return true;

  return getHost(targetUrl) === source;
};

// dsl 序列化成字符串
export const serializeDsl = (dsl: any) => serialize(dsl, {
  space: 2,
  unsafe: true,
}).replace(/"(\w+)":\s/g, '$1: ')

export const serializeCode = (code: any): string =>{
  if(code === '' || code === undefined || code === null) return '';
  if(typeof code === 'string') return code;
  if(code instanceof RegExp) return code.toString();
  return serializeDsl(code);
}


// inputFile 导入文件
export const inputFile = () => new Promise<File>(resolve => {
    const input = document.createElement('input');
    input.type = 'file';
    input.style.display = 'none';
    input.onchange = () => {
      if (input.files?.[0]) {
        resolve(input.files[0]);
      }
    };
    // document.body.appendChild(input);
    input.click();
  })

export const getJsType = (obj: any) => {
  if(typeof obj === 'undefined') {
    return 'undefined';
  }
  if(typeof obj === 'function') {
    return 'function';
  }
  if(obj === null) {
    return 'null'
  }
  if(Array.isArray(obj)) {
    return 'array'
  }
  if(Number.isNaN(obj)) {
    return 'NaN'
  }
  return typeof obj;
}

export const autoIncrementName = (baseName: string, names: string[]): string => {
    const namesSet = new Set(names);
    if(!baseName) {
      return 'undefined_name';
    }
    if(!names || names.length == 0) {
      return baseName;
    }
    let len = 0;
    namesSet.forEach(name => {
       if(name.includes(baseName)) {
        len ++;
       }
    });
    let nameTemp = baseName + len;
    while(namesSet.has(nameTemp)) {
      len ++;
      nameTemp = baseName + len;
    }
    if(len === 0) {
      return baseName;
    }
    return nameTemp;
}

const checkPageType = (sourcePageType: PageTypeEnum, pageType?: PageTypeEnum) => {
  if (pageType) {
    return pageType === sourcePageType;
  }
  // @ts-ignore
  if (window.magicDSL?.[0]?.pageType) {
    // @ts-ignore
    return window.magicDSL[0].pageType === sourcePageType;
  }
  const sessionPageType = sessionStorage.getItem('pageType');
  if (sessionPageType) {
    return parseInt(sessionPageType || '') === sourcePageType;
  }
  return parseInt(getUrlParam('page_type')) == sourcePageType;
}

export const isBusinessPage = (pageType?: PageTypeEnum) => checkPageType(PageTypeEnum.business, pageType);

export const isClientH5Page = (pageType?: PageTypeEnum) => checkPageType(PageTypeEnum.clientH5, pageType);

export const legoPageId = parseInt(getUrlParam('id'));

export const coverObjToObjTree = (obj: any) => {
  if(!obj || typeof obj !== 'object') {
    return obj;
  }
  const tree: any = [];
  Object.keys(obj).forEach((key) => {
    const item: any = {};
    item.label = key;
    item.value = key;
    const value = obj[key]
    if (typeof value === 'object' && value !== null) {
      item.children = coverObjToObjTree(value);
      item.label = `${key}: ${getJsType(value)}`
    }
    else {
      item.label = `${key}: ${value}`
    }
    tree.push(item);
  })
  return tree;
}

export const isInEditor = () => {
  return window.location.pathname.includes('/runtime-vue3/page') || window.location.pathname.includes('/runtime-vue3/playground');
}

// 判断在物理测试环境
export const isInTextMachine = () => {
  const { PROD, VITE_TEST_HOST } = import.meta.env
  return (PROD && globalThis?.location?.origin?.indexOf(VITE_TEST_HOST) > -1)
}

export const getLegoApiPrefix = () => {
  // @ts-ignore
  // 已发布的页面，会在页面中注入miniLegoApiPrefix
  if (window.miniLegoApiPrefix) return window.miniLegoApiPrefix as unknown as string

  // 编辑器环境
  const { VITE_API_PREFIX, VITE_TEST_API_PREFIX } = import.meta.env

  let apiPrefix = VITE_API_PREFIX as string

  if (isInTextMachine()) {
    apiPrefix = VITE_TEST_API_PREFIX
  }

  return apiPrefix
}

export const getDataCoreApiPrefix = () => {
  const legoApiPrefix = getLegoApiPrefix()
  const { VITE_API_PREFIX, VITE_DATACORE_HOST, VITE_TEST_DATACORE_HOST } = import.meta.env
  // 编辑器环境
  if (isInEditor()) {
    return `${legoApiPrefix}/v1/plug`
  }
  // 非子系统打开的页面
  if (!qs.parse(location.search).legoflag) {
    return `${legoApiPrefix}/v1/plug`
  }

  // 子系统打开的页面
  // 生产环境
  if (legoApiPrefix.includes(VITE_API_PREFIX)) {
    return VITE_DATACORE_HOST
  }
  // 测试环境
  return VITE_TEST_DATACORE_HOST
}