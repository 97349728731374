import { DownLoadFileType, DownloadAction } from '@tmagic/schema';
import XLSX from 'xlsx';
import axios from 'axios';

/**
 * 通过a标签的href属性下载
 * @param url
 * @param filename
 */
export const downloadByHref = (url: any, fileName: string) => {
  const a = document.createElement('a');
  console.log(fileName, 'fileName')
  a.href = url;
  a.download = fileName;
  document.body.appendChild(a);
  a.click();

  URL.revokeObjectURL(a.href);
  document.body.removeChild(a);
};

/**
 * 下载blob
 * @param blob
 * @param filename
 */
export const downloadBlod = (blob: Blob, fileName: string) => {
  const url = URL.createObjectURL(blob);
  downloadByHref(url, fileName);
};

// 字符串转换成blob
export const stringToBlob = (txt: string) => {
  const blob = new Blob([txt]);
  return blob;
}


// 把js对象转换成 []
export const jsToSheetArray = (data: any) => {
  let keys = [];
  const values: any = [];
  const aoa = [];
  if(Array.isArray(data)) {
    const data1 = data[0];
    if(typeof data1 === 'object') {
      keys = Object.keys(data1);
      data.forEach((item) => {
        values.push(Object.values(item));
    });
    }
    else {keys = data;}
  }
  else if(typeof data === 'object') {
    keys = Object.keys(data);
  }
  aoa.push(keys);
  aoa.push(...values);
  return  aoa;
}

// 把js对象转换成sheet
export const js2Sheet = (data: any) => {
  const aoa = jsToSheetArray(data);
  const sheet = XLSX.utils.aoa_to_sheet(aoa);
  return sheet;
};
// sheet转换成blob
export const sheet2blob = (sheet: any, sheetName?: string) => {
  sheetName = sheetName || 'sheet1';
  const workbook = {
      SheetNames: [sheetName],
      Sheets: {}
  };
  workbook.Sheets[sheetName] = sheet;
  // 生成excel的配置项
  const wopts = {
      bookType: 'xlsx', // 要生成的文件类型
      bookSST: false, // 是否生成Shared String Table，官方解释是，如果开启生成速度会下降，但在低版本IOS设备上有更好的兼容性
      type: 'binary'
  };
  const wbout = XLSX.write(workbook, wopts);
  const blob = new Blob([s2ab(wbout)], { type: 'application/octet-stream' });
  // 字符串转ArrayBuffer
  function s2ab(s) {
      const buf = new ArrayBuffer(s.length);
      const view = new Uint8Array(buf);
      for (let i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
      return buf;
  }
  return blob;
}

export const downLoadString = (txt: string, fileName: string) => {
  const blod = stringToBlob(txt);
  downloadBlod(blod, fileName);
}

export const downLoadTxt = (txt: string, fileName: string) => {
  downLoadString(txt, fileName);
}

export const downLoadJson = (txt: string, fileName: string) => {
  downLoadString(txt, fileName);
}

export const downLoadExcel = (txt: string, fileName: string) => {
  const sheet = js2Sheet(txt);
  const blod = sheet2blob(sheet);
  downloadBlod(blod, fileName);
}

export const downLoadCsv = (data: any, fileName: string) => {
  const aoa = jsToSheetArray(data);
  let txt = '';
  aoa.forEach(item => txt=`${txt}${item.join(',')}\n`);
  downLoadString(txt, fileName);
}
export const getFileName = (fileName: string, fileType: DownLoadFileType) => {
  if(fileType === DownLoadFileType.URL) {
    return fileName;
  }
  fileName = fileName || 'download';
  fileType = fileType.toLocaleLowerCase() as DownLoadFileType;
  fileName = fileName.split('.')[0];
  return `${fileName}.${fileType}`
}

export const getResponseType = async (url: string) => {
  const res = await axios({
    method: 'head',
    url,
  })
  return res.headers['content-type'];
}

export const getExcelBlobByApi = async (url: string) => {
  const res = await axios.get(url, {
    responseType: 'arraybuffer',
  });
  const blob = new Blob([res.data], { type: 'application/vnd.ms-excel;charset=UTF-8' });
  return blob;
}
export const getBlobByApi = async (url: string) => {
  const res = await axios.get(url, {
    responseType: 'arraybuffer',
  });
  const blob = new Blob([res.data]);
  return blob;
}
export const getJsonBlobByApi = async (url: string) => {
  const res = await axios.get(url);
  const blob = new Blob([JSON.stringify(res.data)]);
  return blob;
}


export const downloadUrl = async (url: string, fileName: string) => {
  try{
    const responseType = await getResponseType(url);
    let blob;
    
    if('application/json' == responseType) {
      fileName = fileName.split('.')[0] + '.json';
      blob = await getJsonBlobByApi(url);
    }
    else if('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' == responseType){
      fileName = fileName.split('.')[0] + '.xlsx';
      blob = await getExcelBlobByApi(url)
    }
    else {
      if(!fileName.includes('.')) {
        fileName = fileName + '.' + responseType.split('/')[1];
      }
      blob = await getBlobByApi(url);
    }
    blob && downloadBlod(blob, fileName);
  }
  catch(e) {
    downloadByHref(url, fileName);
  }


}

export const download = async (downConfig: DownloadAction) => {
  const { fileType, fileData } = downConfig;

  let fileName = getFileName(downConfig.fileName, fileType);
  // url类型直接下载
  if(fileType === DownLoadFileType.URL) {
    downloadUrl(fileData, fileName);
  }
  else if(fileType === DownLoadFileType.TXT) {
    downLoadTxt(fileData, fileName);
  }
  else if(fileType === DownLoadFileType.JSON) {
    downLoadJson(fileData, fileName);
  }
  else if(fileType === DownLoadFileType.CSV) {
    downLoadCsv(fileData, fileName);
  }
  else if(fileType === DownLoadFileType.Excel) {
    downLoadExcel(fileData, fileName);
  }

}