import { ComponentPublicInstance, inject, toRaw, watchEffect } from 'vue';
import Core from '@tmagic/core';

function runInterpolationExpression(props: any, app: any, listItemData?: any, parentsExtraData?: any) {
  Object.keys(props).forEach(key => {
    const prop = props[key]
    if (key === '__code__'|| key === 'items') return
    const interpolationFun = props?.__code__?.[key]
    if (interpolationFun) {
      props[key] = app.runScript(interpolationFun, parentsExtraData);     
    } else if (typeof toRaw(prop) === 'object' && prop !== null) {
      props[key] = runInterpolationExpression(prop, app, listItemData, parentsExtraData)
    }
  })
  return props
}

export function calcInterpolationExpression() {
  return {
    componentSetup: (app: Core, vm: ComponentPublicInstance, componentConfig: any) => {
      const listItemData = inject('listItemData', undefined);
      const parentsExtraData = inject('parentsExtraDataForChildren', undefined);
      watchEffect(() => {
        // @ts-ignore
        runInterpolationExpression(vm.config, app, listItemData, parentsExtraData);
      })
    },
  }
}