import Core from '@tmagic/core';

const pluginsInstance: Record<any, any> = {}

let pluginsInitSatatusPromise = new Promise(() => {});
export const checkPluginsInit = async () => {
  return pluginsInitSatatusPromise
}

export const intsallPlugins = async (plugins: any, pluginsConfig: any = {}) => {
  let pluginsInitSatatusResolve: any
  pluginsInitSatatusPromise = new Promise((resolve) => {
    pluginsInitSatatusResolve = resolve
  })
  for (const key of Object.keys(plugins).filter((key) => key.startsWith('basic:'))) {
    pluginsInstance[key] = await plugins[key]()
  }
  for (const config of pluginsConfig) {
    const { type, plugin_id, enable } = config
    if (enable) {
      if (plugins[`${type}-${plugin_id}`]) {
        pluginsInstance[`${type}-${plugin_id}`] = await plugins[`${type}-${plugin_id}`](config)
      }
    }
  }
  pluginsInitSatatusResolve();
}

export const runPluginsLifecycleFunction = async (type: string, app?: Core, ...args: any) => {
  const pluginsInstanceList = Object.values(pluginsInstance)
  for (const plugin of pluginsInstanceList) {
    if (plugin?.[type]) {
      if (type === 'componentSetup') {
        plugin[type](app, ...args)
      } else {
        await plugin[type](app, ...args)
      }
    }
  }
}
