import anime from 'animejs';
import App from './App';
import Node from './Node';
import { getCommonEventName } from './events';

window.anime = anime;

export const baseAnimationMethods = [
  { 
    text: '正常展示',
    value: 'normal',
  },
  { 
    text: '淡入',
    value: 'fadeIn',
    animationParams: {
      opacity: [0, 1]
    },
  },
  { 
    text: '向下淡入', 
    value: 'fadeInDown',
    animationParams: {
      opacity: [0, 1],
      translateY: ['-100%', '0%'],
    },
  },
  { 
    text: '向右淡入', 
    value: 'fadeInRight',
    animationParams: {
      opacity: [0, 1],
      translateX: ['-100%', '0%'],
    },
  },
  {
    text: '向左淡入',
    value: 'fadeInLeft',
    animationParams: {
      opacity: [0, 1],
      translateX: ['100%', '0%'],
    },
  },
  {
    text: '向上淡入', 
    value: 'fadeInUp',
    animationParams: {
      opacity: [0, 1],
      translateY: ['100%', '0%'],
    },
  },
  {
    text: '中心弹入',
    value: 'bounceIn',
    animationParams: {
      keyframes: [
        {
          opacity: 0,
          scale: 0.3,
          duration: 0,
        },
        {
          opacity: 0.33,
          scale: 1.1,
        },
        {
          opacity: 0.66,
          scale: 0.9,
        },
        {
          opacity: 1,
          scale: 1.03,
        },
        {
          scale: 0.97,
        },
        {
          opacity: 1,
          scale: 1,
        }
      ]
    },
  },
  {
    text: '向下弹入',
    value: 'bounceInDown',
    animationParams: (duration: number) => {
      return {
        keyframes: [
          {
            opacity: 0,
            translateY: '-3000px',
            duration: 0,
          },
          {
            opacity: 1,
            translateY: '25%',
            duration: duration * 0.6,
          },
          {
            translateY: '-10%',
            duration: duration * 0.15,
          },
          {
            translateY: '5%',
            duration: duration * 0.15,
          },
          {
            translateY: '0%',
            duration: duration * 0.1,
          },
        ]
      }
    },
  },
  {
    text: '向右弹入',
    value: 'bounceInRight',
    animationParams: (duration: number) => {
      return {
        keyframes: [
          {
            opacity: 0,
            translateX: '-3000px',
            duration: 0,
          },
          {
            opacity: 1,
            translateX: '25%',
            duration: duration * 0.6,
          },
          {
            translateX: '-10%',
            duration: duration * 0.15,
          },
          {
            translateX: '5%',
            duration: duration * 0.15,
          },
          {
            translateX: '0%',
            duration: duration * 0.1,
          },
        ]
      }
    },
  },
  { 
    text: '向左弹入',
    value: 'bounceInLeft',
    animationParams: (duration: number) => {
      return {
        keyframes: [
          {
            opacity: 0,
            translateX: '3000px',
            duration: 0,
          },
          {
            opacity: 1,
            translateX: '-25%',
            duration: duration * 0.6,
          },
          {
            translateX: '10%',
            duration: duration * 0.15,
          },
          {
            translateX: '-5%',
            duration: duration * 0.15,
          },
          {
            translateX: '0%',
            duration: duration * 0.1,
          },
        ]
      }
    }
  },
  {
    text: '向上弹入',
    value: 'bounceInUp',
    animationParams: (duration: number) => {
      return {
        keyframes: [
          {
            opacity: 0,
            translateY: '3000px',
            duration: 0,
          },
          {
            opacity: 1,
            translateY: '-25%',
            duration: duration * 0.6,
          },
          {
            translateY: '10%',
            duration: duration * 0.15,
          },
          {
            translateY: '-5%',
            duration: duration * 0.15,
          },
          {
            translateY: '0%',
            duration: duration * 0.1,
          },
        ]
      }
    },
  },
  {
    text: 'X轴翻转(进入)',
    value: 'flipInX',
    animationParams: (duration: number) => {
      return {
        keyframes: [
          {
            opacity: 0,
            rotateX: '-90deg',
            perspective: 400,
            duration: 0,
          },
          {
            opacity: 0.66,
            rotateX: '20deg',
            perspective: 400,
            duration: duration * 0.4,
          },
          {
            rotateX: '-10deg',
            perspective: 400,
            duration: duration * 0.2,
          },
          {
            rotateX: '5deg',
            perspective: 400,
            duration: duration * 0.2,
          },
          {
            rotateX: '0deg',
            perspective: 400,
            duration: duration * 0.2,
          },
        ]
      }
    },
  },
  {
    text: 'Y轴翻转(进入)',
    value: 'flipInY',
    animationParams: (duration: number) => {
      return {
        keyframes: [
          {
            opacity: 0,
            rotateY: '-90deg',
            perspective: 400,
            duration: 0,
          },
          {
            opacity: 0.66,
            rotateY: '20deg',
            perspective: 400,
            duration: duration * 0.4,
          },
          {
            rotateY: '-10deg',
            perspective: 400,
            duration: duration * 0.2,
          },
          {
            rotateY: '5deg',
            perspective: 400,
            duration: duration * 0.2,
          },
          {
            rotateY: '0deg',
            perspective: 400,
            duration: duration * 0.2,
          },
        ]
      }
    },
  },
  {
    text: '光速向左(进入)',
    value: 'lightSpeedInLeft',
    animationParams: (duration: number) => {
      return {
        keyframes: [
          {
            opacity: 0,
            translateX: '300%',
            skewX: '-30deg',
            duration: 0,
          },
          {
            opacity: 1,
            translateX: '0%',
            skewX: '20deg',
            duration: duration * 0.6,
          },
          {
            skewX: '-5deg',
            duration: duration * 0.2,
          },
          {
            skewX: '0deg',
            duration: duration * 0.2,
          },
        ]
      }
    },
  },
  {
    text: '光速向右(进入)',
    value: 'lightSpeedInRight',
    animationParams: (duration: number) => {
      return {
        keyframes: [
          {
            opacity: 0,
            translateX: '-300%',
            skewX: '30deg',
            duration: 0,
          },
          {
            opacity: 1,
            translateX: '0%',
            skewX: '-20deg',
            duration: duration * 0.6,
          },
          {
            skewX: '5deg',
            duration: duration * 0.2,
          },
          {
            skewX: '0deg',
            duration: duration * 0.2,
          },
        ]
      }
    },
  },
  {
    text: '光速向上(进入)',
    value: 'lightSpeedInUp',
    animationParams: (duration: number) => {
      return {
        keyframes: [
          {
            opacity: 0,
            translateY: '300%',
            skewY: '-30deg',
            duration: 0,
          },
          {
            opacity: 1,
            translateY: '0%',
            skewY: '20deg',
            duration: duration * 0.6,
          },
          {
            skewY: '-5deg',
            duration: duration * 0.2,
          },
          {
            skewY: '0deg',
            duration: duration * 0.2,
          },
        ]
      }
    },
  },
  {
    text: '光速向下(进入)',
    value: 'lightSpeedInDown',
    animationParams: (duration: number) => {
      return {
        keyframes: [
          {
            opacity: 0,
            translateY: '-300%',
            skewY: '30deg',
            duration: 0,
          },
          {
            opacity: 1,
            translateY: '0%',
            skewY: '-20deg',
            duration: duration * 0.6,
          },
          {
            skewY: '5deg',
            duration: duration * 0.2,
          },
          {
            skewY: '0deg',
            duration: duration * 0.2,
          },
        ]
      }
    },
  },
  {
    text: '中心旋转(进入)',
    value: 'rotateIn',
    animationParams: {
      opacity: [0, 1],
      'transform-origin': 'center',
      rotate: ['-30deg', '0'],
    }
  },
  {
    text: '左下旋转(进入)',
    value: 'rotateInDownLeft',
    animationParams: {
      opacity: [0, 1],
      rotate: ['-90deg', '0'],
      begin(anim: any) {
        anim.animatables[0].target.style.transformOrigin = 'left bottom';
      },
    },
  },
  {
    text: '右下旋转(进入)',
    value: 'rotateInDownRight',
    animationParams: {
      opacity: [0, 1],
      rotate: ['90deg', '0'],
      begin(anim: any) {
        anim.animatables[0].target.style.transformOrigin = 'right bottom';
      },
    },
  },
  {
    text: '左上旋转(进入)',
    value: 'rotateInUpLeft',
    animationParams: {
      opacity: [0, 1],
      rotate: ['90deg', '0'],
      begin(anim: any) {
        anim.animatables[0].target.style.transformOrigin = 'left top';
      },
    },
  },
  { 
    text: '右上旋转(进入)', 
    value: 'rotateInUpRight',
    animationParams: {
      opacity: [0, 1],
      rotate: ['-90deg', '0'],
      begin(anim: any) {
        anim.animatables[0].target.style.transformOrigin = 'right top';
      },
    },
  },
  { 
    text: '旋转抖动(进入)', 
    value: 'jackInTheBox',
    animationParams: (duration: number) => {
      return {
        keyframes: [
          {
            opacity: 0,
            scale: 0.1,
            rotate: '-360deg',
            duration: 0,
          },
          {
            opacity: 1,
            scale: 1,
            rotate: '-10deg',
            duration: duration * 0.5,
          },
          {
            rotate: '3deg',
            duration: duration * 0.1,
          },
          {
            rotate: '-10deg',
            duration: duration * 0.1,
          },
          {
            rotate: '0',
            duration: duration * 0.3,
          },
        ]
      }
    },
  },
  {
    text: '从左滚入',
    value: 'rollInLeft',
    animationParams: {
      opacity: [0, 1],
      translateX: ['-100%', '0%'],
      rotate: ['-180deg', '0deg'],
    },
  },
  {
    text: '从右滚入',
    value: 'rollInRight',
    animationParams: {
      opacity: [0, 1],
      translateX: ['100%', '0%'],
      rotate: ['180deg', '0deg'],
    },
  },
  {
    text: '从上滚入',
    value: 'rollInUp',
    animationParams: {
      opacity: [0, 1],
      translateY: ['-100%', '0%'],
      rotate: ['-180deg', '0deg'],
    },
  },
  { 
    text: '从下滚入',
    value: 'rollInDown',
    animationParams: {
      opacity: [0, 1],
      translateY: ['100%', '0%'],
      rotate: ['180deg', '0deg'],
    },
  },
  { 
    text: '中心放大(进入)',
    value: 'zoomIn',
    animationParams: {
      opacity: [0, 1],
      scale: [0.1, 1],
    },
  },
  {
    text: '向下放大(进入)',
    value: 'zoomInDown',
    animationParams: (duration: number) => {
      return {
        keyframes: [
          {
            opacity: 0,
            scale: 0.1,
            translateY: '-1000px',
            duration: 0,
          },
          {
            opacity: 1,
            scale: 0.475,
            translateY: '20%',
            duration: duration * 0.6,
          },
          {
            scale: 1,
            translateY: '0',
            duration: duration * 0.4,
          },
        ],
        begin(anim: any) {
          anim.animatables[0].target.style.transformOrigin = 'center bottom';
        }
      }
    },
  },
  {
    text: '向左放大(进入)',
    value: 'zoomInLeft',
    animationParams: (duration: number) => {
      return {
        keyframes: [
          {
            opacity: 0,
            scale: 0.1,
            translateX: '1000px',
            duration: 0,
          },
          {
            opacity: 1,
            scale: 0.475,
            translateX: '-20%',
            duration: duration * 0.6,
          },
          {
            scale: 1,
            translateX: '0',
            duration: duration * 0.4,
          },
        ],
        begin(anim: any) {
          anim.animatables[0].target.style.transformOrigin = 'left center';
        },
      }
    },
  },
  {
    text: '向右放大(进入)',
    value: 'zoomInRight',
    animationParams: (duration: number) => {
      return {
        keyframes: [
          {
            opacity: 0,
            scale: 0.1,
            translateX: '-1000px',
            duration: 0,
          },
          {
            opacity: 1,
            scale: 0.475,
            translateX: '20%',
            duration: duration * 0.6,
          },
          {
            scale: 1,
            translateX: '0',
            duration: duration * 0.4,
          },
        ],
        begin(anim: any) {
          anim.animatables[0].target.style.transformOrigin = 'right center';
        },
      }
    },
  },
  {
    text: '向上放大(进入)',
    value: 'zoomInUp',
    animationParams: (duration: number) => {
      return {
        keyframes: [
          {
            opacity: 0,
            scale: 0.1,
            translateY: '1000px',
            duration: 0,
          },
          {
            opacity: 1,
            scale: 0.475,
            translateY: '-20%',
            duration: duration * 0.6,
          },
          {
            scale: 1,
            translateY: '0',
            duration: duration * 0.4,
          },
        ],
        begin(anim: any) {
          anim.animatables[0].target.style.transformOrigin = 'center top';
        }
      }
    },
  },
  { 
    text: '缩小(进入)',
    value: 'puffIn',
    animationParams: {
      opacity: [0, 1],
      scale: [3, 1],
    },
  },
  { 
    text: '向左缩小(进入)',
    value: 'puffInLeft',
    animationParams: {
      opacity: [0, 1],
      scale: [3, 1],
      translateX: ['100%', '0%'],
    },
  },
  { 
    text: '向右缩小(进入)',
    value: 'puffInRight',
    animationParams: {
      opacity: [0, 1],
      scale: [3, 1],
      translateX: ['-100%', '0%'],
    },
  },
  { 
    text: '向上缩小(进入)',
    value: 'puffInUp',
    animationParams: {
      opacity: [0, 1],
      scale: [3, 1],
      translateY: ['100%', '0%'],
    },
  },
  { 
    text: '向下缩小(进入)',
    value: 'puffInDown',
    animationParams: {
      opacity: [0, 1],
      scale: [3, 1],
      translateY: ['-100%', '0%'],
    },
  },
  {
    text: '弹跳',
    value: 'bounce',
    animationParams: (duration: number) => {
      return {
        keyframes: [
          {
            translateY: '0',
            duration: duration * 0.2,
            easing: 'cubicBezier(0.215,0.61,0.355,1)',
          },
          {
            translateY: '-30%',
            duration: duration * 0.2,
            easing: 'cubicBezier(0.755,0.05,0.855,0.06)',
          },
          {
            translateY: '-30%',
            duration: duration * 0.03,
            easing: 'cubicBezier(0.755,0.05,0.855,0.06)',
          },
          {
            translateY: '0',
            duration: duration * 0.1,
            easing: 'cubicBezier(0.215,0.61,0.355,1)',
          },
          {
            translateY: '-15%',
            duration: duration * 0.17,
            easing: 'cubicBezier(0.755,0.05,0.855,0.06)',
          },
          {
            translateY: '0',
            duration: duration * 0.1,
            easing: 'cubicBezier(0.215,0.61,0.355,1)',
          },
          {
            translateY: '-4%',
            duration: duration * 0.1,
          },
          {
            translateY: '0',
            duration: duration * 0.1,
            easing: 'cubicBezier(0.215,0.61,0.355,1)',
          }
        ],
        begin(anim: any) {
          anim.animatables[0].target.style.transformOrigin = 'center bottom';
        },
      }
    },
  },
  {
    text: '闪烁',
    value: 'flash',
    animationParams: {
      opacity: [1, 0.2, 1, 0.2, 1, 0.2, 1],
    },
  },
  {
    text: '脉冲',
    value: 'pulse',
    animationParams: {
      scale: [1, 1.5, 1],
    },
  },
  {
    text: '弹性抖动',
    value: 'rubberBand',
    animationParams: (duration: number) => {
      return {
        keyframes: [
          {
            scaleX: 1.25,
            scaleY: 0.75,
            duration: duration * 0.3,
          },
          {
            scaleX: 0.75,
            scaleY: 1.25,
            duration: duration * 0.1,
          },
          {
            scaleX: 1.15,
            scaleY: 0.85,
            duration: duration * 0.1,
          },
          {
            scaleX: 0.95,
            scaleY: 1.05,
            duration: duration * 0.15,
          },
          {
            scaleX: 1.05,
            scaleY: 0.95,
            duration: duration * 0.1,
          },
          {
            scaleX: 1,
            scaleY: 1,
            duration: duration * 0.25,
          },
        ],
      }
    },
  },
  {
    text: '剧烈抖动',
    value: 'shake',
    animationParams: {
      translateX: ['0', '-10%', '10%', '-10%', '10%', '-10%', '10%', '-10%', '10%', '0'],
    },
  },
  {
    text: '抖动',
    value: 'headShake',
    animationParams: {
      translateX: ['0', '-6%', '5%', '-3%', '2%', '0'],
    },
  },
  {
    text: '放大抖动',
    value: 'tada',
    animationParams: {
      scale: [0.9, 0.9, 1.1, 1.1, 1.1, 1.1, 1.1, 1.1, 1.1, 1],
      rotate: ['-3deg', '-3deg', '3deg', '-3deg', '3deg', '-3deg', '3deg', '-3deg', '3deg', '0'],
    },
  },
  {
    text: '摇摆',
    value: 'swing',
    animationParams: {
      rotate: ['-15deg', '10deg', '-5deg', '5deg', '0deg'],
      begin(anim: any) {
        anim.animatables[0].target.style.transformOrigin = 'top center';
      },
    },
  },
  {
    text: '摇晃',
    value: 'wobble',
    animationParams: {
      translateX: ['-25%', '20%', '-15%', '10%', '-5%', '0%'],
      rotate: ['-5deg', '3deg', '-3deg', '2deg', '-1deg', '0deg'],
    },
  },
  {
    text: '倾斜摆动',
    value: 'jello',
    animationParams: {
      skewX: [ '0deg', '-12.5deg', '6.25deg', '-3.125deg', '1.5625deg', '-0.78125deg', '0.390625de', '-0.1953125deg', '0deg'],
      skewY: [ '0deg', '-12.5deg', '6.25deg', '-3.125deg', '1.5625deg', '-0.78125deg', '0.390625de', '-0.1953125deg', '0deg'],
    },
  },
  {
    text: '心跳',
    value: 'heartBeat',
    animationParams: (duration: number) => {
      return {
        keyframes: [
          {
            scale: 1.3,
            duration: duration * 0.14,
          },
          {
            scale: 1,
            duration: duration * 0.14,
          },
          {
            scale: 1.3,
            duration: duration * 0.14,
          },
          {
            scale: 1,
            duration: duration * 0.28,
          },
          {
            scale: 1,
            duration: duration * 0.3,
          },
        ],
      }
    },
  },
  {
    text: '旋转',
    value: 'rotating',
    animationParams: {
      rotate: ['0deg', '360deg'],
    },
  },
]

const baseAnimationMethodsMap = baseAnimationMethods.reduce((pre, cur) => {
  pre[cur.value] = cur;
  return pre;
}, {} as Record<string, any>);

export async function execAnimation(node: Node, reverse = false) {
  const { animation, id } = node.data;
  if (animation) {
    const { method, delay, duration, loop, custom } = animation;
    let animationOpts: any = {
      delay: delay || 0,
      easing: 'easeInOutSine',
      duration,
      direction: reverse ? 'reverse' : 'normal',
      loop: loop || true,
    };
    if (custom) {
      if (typeof custom === 'function') {
        animationOpts = {
          ...animationOpts,
          ...custom(),
        }
      } else {
        animationOpts = {
          ...animationOpts,
          ...custom,
        }
      }
    } else {
      if (!method || method === 'normal') return;
      const animationParams = typeof baseAnimationMethodsMap[method].animationParams === 'function' 
        ? baseAnimationMethodsMap[method].animationParams(duration)
          : baseAnimationMethodsMap[method].animationParams;

      animationOpts = {
        ...animationOpts,
        ...animationParams,
      }
    }
    return anime({
      ...animationOpts,
      targets: `#${id}`,
      begin(...args) {
        node.app.emit(getCommonEventName('animeStart'), node);
        animationOpts.begin?.(...args);
      },
      complete(...args) {
        node.app.emit(getCommonEventName('animeEnd'), node);
        animationOpts.complete?.(...args);
      },
    });
  }
}