import { asyncLoadJs } from '@tmagic/utils';

const importComponentsPromise = (async () => {
  const pluginsConfig = window.magicDSL[0]?.plugins

  const pluginsAsync: Record<string, any> = {}
  await Promise.all(Object.values(pluginsConfig).map(async ({ type, version, plugin_id }) => {
    if (!pluginsConfig[`${type}-${plugin_id}`].enable) return
    await asyncLoadJs(`${window.assetsPrefix || 'https://web-static.mini1.cn'}/lego/svc-files/plugins/production/${type}-${plugin_id}/${version}/plugin.js`)
    // @ts-ignore
    pluginsAsync[`${type}-${plugin_id}`] = window[`tmagic-plugin-${type}-${plugin_id}`]
  }))
  return pluginsAsync
})()

export default async function importComponents() {
  return importComponentsPromise
}