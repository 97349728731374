import { EventEmitter } from "events";
import { computed, reactive, watchEffect } from "vue";
import { cloneDeep, isEqual } from "lodash-es";
import Core from "@tmagic/core";
import DataCoreRequest from "./dataCoreRequest";
import {
  DataServiceQueryConfigure,
  ExecuteActionRequest,
  ExecuteType,
  MApp,
  PagePlugPluginName,
  KeyValuePair,
  isFrontQuery,
  EBodyType,
  ServiceConfigure,
  isJsQuery
} from "@tmagic/schema";
import { getJsType } from "@tmagic/utils";
import { getCode, getActionConfigParams, runScript, isCode, hasCode, getParamsInString } from "./script";

const isInEditor = location.href.indexOf("playground") > -1;
export default class DataService extends EventEmitter {
  public app!: Core;
  public pages: any = {};
  public dataServiceConfigure: Record<string, DataServiceQueryConfigure> = {};
  public oldDataServiceConfigure: Record<string, DataServiceQueryConfigure> = {};
  public state: Record<string, any> = reactive({});
  public intervalMap: Record<string, any> = {};
  public componentsMap: Record<string, any> = {};
  public status: Record<string, boolean> = {};
  public queryWatchEffectStopHanderMap: Record<string, any> = {};
  public dataCoreRequest = new DataCoreRequest();
  constructor(dataServiceConfigure?: ServiceConfigure[]) {
    super();
    this.updateConfigure(dataServiceConfigure);
  }
  // 更新配置
  public updateConfigure(dataServiceConfigure?: ServiceConfigure[]) {
    if (!dataServiceConfigure) return;
    const configList: DataServiceQueryConfigure[] = [];
    this.getLeafConfigList(cloneDeep(dataServiceConfigure), configList);
    const tempDataServiceConfigure: Record<string, any> = {};
    // 排序 js类型的排前面
    configList.sort((a: DataServiceQueryConfigure, b: DataServiceQueryConfigure) => {
      return (a.pluginName === PagePlugPluginName.Env || isJsQuery(a.pluginName)) && b.pluginName !== PagePlugPluginName.Env ? -1 : 1;
    })
    configList?.forEach((item) => {
      tempDataServiceConfigure[item.id] = item;
      if (!this.state[item.name]) {
        this.state[item.name] = {};
        this.state[item.name].run = () => this.launchService(item.id);
      }
    });
    this.oldDataServiceConfigure = cloneDeep(this.dataServiceConfigure);
    this.dataServiceConfigure = tempDataServiceConfigure;

    // 清理已经不存在的服务
    Object.values(this.oldDataServiceConfigure).forEach((config: DataServiceQueryConfigure) => {
      const { id, name } = config;
      if (!this.dataServiceConfigure[id]) {
        delete this.state[name];
      }
    });

    // 触发自动请求
    // 等待组件渲染完再执行，暂时这么做 todo 优化
    setTimeout(() => {
      this.launchAutoServices();
    })
  }

  public getLeafConfigList(tree: ServiceConfigure[], list: Array<DataServiceQueryConfigure>) {
    tree.forEach((item: any) => {
      if(item.list) {
        this.getLeafConfigList(item.list, list)
      }
      else {
        list.push(item);
      }
    })
  }
  // 执行自动请求
  public launchAutoServices() {
    const keys = Object.keys(this.dataServiceConfigure);
    keys.forEach((id) => {
      if (!isEqual(this.oldDataServiceConfigure[id], this.dataServiceConfigure[id])) {
        const oldName = this.oldDataServiceConfigure[id]?.name;
        const newName = this.dataServiceConfigure[id]?.name;
        if (oldName && newName && oldName !== newName) {
          this.state[newName] = this.state[oldName];
          delete this.state[oldName];
        }

        const { executeType } = this.getRealConfig(id).extraConfiguration;
        if (executeType !== ExecuteType.manual) {
          this.launchService(id);
        }
      }
    });
  }

  public async launchService(id: string) {
    if (!this.dataServiceConfigure[id]) {
      console.error(`没有找到id为${id}的服务`);
      return;
    }
    const config = this.getRealConfig(id);
    const { name } = config;
    const { loop, interval, executeType, showConfirm, confirmMessage } = config.extraConfiguration;
    // 手动执行前确认
    if (showConfirm && executeType === ExecuteType.manual && !isInEditor) {
      const result = await this.app.confirm(
        confirmMessage || "确认执行本次操作?"
      );
      if (!result) {
        return;
      }
    }

    let res;
    if (this.isJavascriptQuery(id)) {
      res = this.executeScript(id);
      // 执行完成后
      this.excuteResultNotic(id, res);
    } else {
      res = await this.executeQuery(id);
      // 非编辑器中才执行定时操作，防止编辑器卡死
      if (loop && executeType !== ExecuteType.manual) {
        // 清除 定时器
        this.clearInterval(id);
        this.loopExecuteQuery(id, interval as number);
        this.state[name].stop = () => this.clearInterval(id);
      }
    }

    return res;
  }

  public loopExecuteQuery(id: string, interval: number) {

    this.intervalMap[id] = setTimeout(
      async () => {
        const res = await this.executeQuery(id);
        this.excuteResultNotic(id, res);
        this.loopExecuteQuery(id, interval);
      },
      interval > 200 ? interval : 200
    );
  }

  public async executeQuery(id: string) {
    const config = this.getRealConfig(id);
    const isDone = this.status[id];
    const { executeType } = config.extraConfiguration;
    let res;
    if (executeType === ExecuteType.manual || isInEditor || isDone) {
      res = await this.runQuery(id);
    } else {
      res = await this.runEffectQuery(id);
    }
    return res;
  }

  public async runQuery(id: string) {
    const { name } = this.dataServiceConfigure[id];
    const res = await this.requestQuery(id);
    this.state[name].data = res;
    const result = {
      code: 0,
      data: res,
    };
    this.excuteResultNotic(id, result);
    return result;
  }
  public async runEffectQuery(id: string) {
    const config = this.getRealConfig(id);
    const { name } = this.dataServiceConfigure[id];
    const { executeType } = config.extraConfiguration;
    this.queryWatchEffectStopHanderMap[id]?.();
    this.queryWatchEffectStopHanderMap[id] = watchEffect( async () => {
      try {
        const res = await this.requestQuery(id);
          if (!isEqual(this.state[name].data, res)) {
            this.state[name].data = res;
            this.excuteResultNotic(id, { data: res, code: 0 });
          }
          this.status[id] = true;
          // 非手动执行取消 watcheffect
          if (executeType !== ExecuteType.inputChange) {
            this.queryWatchEffectStopHanderMap[id]?.();
          }
      } catch (e: any) {
        console.log(e.message);
      }
    });
  }

  public async requestQuery(id: string) {
    const { pluginName } = this.getRealConfig(id);

    if(!isFrontQuery(pluginName))
    {
      return await this.requstQueryByDataCore(id);
    }

    return await this.requesQueryDirect(id);
  }

  public async requesQueryDirect(id: string) {
    const requestConfig = this.createRequestConfig(id);
    return await this.dataCoreRequest.runQueryDirect(requestConfig);
  }

  public async requstQueryByDataCore(id: string) {
    const config = this.getRealConfig(id);
    const { bindings, executeActionDTO } = this.createQueryParams(id);
    const fromData = this.evaluateActionParams(bindings, executeActionDTO);
    const res = await this.dataCoreRequest.runQuery(config, fromData);
    return res;
  }


  public executeScript(id: string) {
    const config = this.getRealConfig(id);
    const { name, pluginName } = this.dataServiceConfigure[id];
    const { executeType } = config.extraConfiguration;
    let { code } = config.extraConfiguration;
    if(pluginName === PagePlugPluginName.Env) {
      if(this.isProd()) {
        code = config.extraConfiguration.prodCode;
      }
      else {
        code = config.extraConfiguration.testCode;
      }
    }

    let result;
    let resultCode = 0;
    try {
      result = this.runScriptNoCatch(code as string)
    }
    catch(e: any) {
      resultCode = -1;
      result = e.message;
    }
    if (executeType === ExecuteType.inputChange) {
      this.state[name].data = computed(() => {
        const data = this.runScriptNoCatch(code as string);
        return data;
      });
    } else {
      if(resultCode !== -1) {
        this.state[name].data = result;
      }
    }

    return {
      code: resultCode,
      data: result,
    };
  }

  // 运行script 获取 返回状态
  public runScriptReturnStatus(code: string, extra?: any) {
    let res;
    let resultCode = 0;
    try {
      res =  runScript(code, this.state, this.app.componentInsExposeds, extra);
    } catch (e: any) {

      res = e.message;
      resultCode = -1;
    }
    return {
      code: resultCode,
      data: res
    }
  }

  public setTempState(id: string, code: string, extra?: any) {
    const config = this.dataServiceConfigure[id] as DataServiceQueryConfigure;
    const { name } = config;
    this.state[name].data = this.runScript(code, extra);
  }
  public runScript(code: string, extra?: any) {
    try {
      return runScript(code, this.state, this.app.componentInsExposeds, extra);
    } catch (e: any) {
      console.log(e.message);
      return '';
    }
  }

  public runScriptNoCatch(code: string, extra?: any) {
    return runScript(code, this.state, this.app.componentInsExposeds, extra);
  }

  public runScriptWithMessage(code: string, extra?: any) {
    try {
      return runScript(code, this.state, this.app.componentInsExposeds, extra);
    } catch (e: any) {
      console.log(e.message);
      return e.message;
    }
  }
  public deleteQueryConfigById(id: string) {
    const { name } = this.dataServiceConfigure[id];
    delete this.state[name];
    this.clearInterval(id);
  }

  public excuteResultNotic(id: string, res: any) {
    const config = this.getRealConfig(id);
    const { name } = this.dataServiceConfigure[id];
    const { condition, isErrorNotic, isSuccessNotic, successMessage, duration } =
      config.extraConfiguration;
    if (!condition || (condition && !this.runScript(condition, { res }))) {
      this.app?.emit(`querySuccess_${id}`, null);
      if (isSuccessNotic) {
        this.app.toast({
          message: successMessage,
          type: "success",
          duration,
        });
      }
    } else {
      if (isErrorNotic) {
        this.app.toast({
          message: `执行 ${name} 失败`,
          type: "error",
          duration,
        });
      }
      this.app?.emit(`queryError_${id}`, null);
    }
  }

  public createRequestConfig(id: string) {
    const realConfig = this.getRealConfig(id);
    let { actionConfiguration: queryConfig, url } = this.coverMixsCodeToString(realConfig);
    const { timeoutInMillisecond } = queryConfig;
    url = url ? url: '';
    const reqUrl = url + queryConfig.path;
    const method = queryConfig.httpMethod;
    const headers: any = {};
    const queryParameters: any = {};
    let bodyFormData: any = {};
    const body = this.coverJsonCodeToString(realConfig.actionConfiguration?.body as string);
    queryConfig.bodyFormData?.forEach((param: KeyValuePair) => {
      if(param.key && param.value) {
        bodyFormData[param.key] = param.value;
      }
    });
    queryConfig.headers?.forEach((header: KeyValuePair) => {
      if(header.key && header.value) {
        headers[header.key] = header.value;
      }
    });
    queryConfig.queryParameters?.forEach((parameter: KeyValuePair) => {
      if(parameter.key && parameter.value) {
        queryParameters[parameter.key] = parameter.value;
      }
    });
    if(queryConfig.formData.apiContentType === EBodyType.JSON) {
      bodyFormData = typeof body === 'string' ? eval(`(${body})`) : body;
    }
    else if(queryConfig.formData.apiContentType === EBodyType.Raw) {
      bodyFormData = typeof body === 'string' ? body : JSON.stringify(body);
    }
    else if(queryConfig.formData.apiContentType === EBodyType.FormData) {
      const  formData = new FormData();
      Object.keys(bodyFormData).forEach((key: string) => {
        if(key) {
          formData.append(key, bodyFormData[key]);
        }
      })
      bodyFormData = formData;
    }
    else if(queryConfig.formData.apiContentType === EBodyType.xWwwFormUrlencoded) {
      const  formData = new URLSearchParams();
      Object.keys(bodyFormData).forEach((key: string) => {
        if(key) {
          formData.append(key, bodyFormData[key]);
        }
      })
      bodyFormData = formData;
    }

    return {
      url: reqUrl,
      method,
      data: bodyFormData,
      timeout: timeoutInMillisecond,
      params: queryParameters,
      headers
    }
  }

  public coverJsonCodeToString(code: string, extra?: any) {
    if(!code || typeof code !== 'string') return code;
    return code.replace(/\{\{(.*?)\}\}/g, (code: string) => {
      const res = this.runScript(code, extra);
      if(typeof res === 'string') {
        return `"${res}"`
      }
      return res;
    });
  }

  public coverStringCodeToString(code: string, extra?: any) {
    if(!code || typeof code !== 'string') return code;
    if(isCode(code)) {
      return this.runScript(code, extra);
    }
    const bindings: string[] = [];
    getParamsInString(code, bindings);
    bindings.forEach(codeStr => {
      code = code.replace(codeStr, this.runScript(codeStr, extra))
    })
    // return code.replace(/\{\{(.*?)\}\}/g, (code: string) => this.runScript(code, extra));
    return code;
  }

  // 把string和code混合的物转换成 string 如: aaa{{111}} 转换成 aaa111
  public coverMixsCodeToString(params: any, extra?: any) {
    if(!params) return;
    const paramsTemp = cloneDeep(params);
    const keys = Object.keys(params);
    keys.forEach((key) => {
      if (typeof paramsTemp[key] === "object") {
        paramsTemp[key] = this.coverMixsCodeToString(paramsTemp[key], extra);
      } else {
        if (hasCode(paramsTemp[key])) {
          paramsTemp[key] =  this.coverStringCodeToString(paramsTemp[key], extra); 
        }
      }
    });
    return paramsTemp;
  }

  // 获取参数中的 值。 类似于将 {a: {{111}}} 转换成 {a: 111}
  public coverCodeToString(params: any, extra?: any) {
    if(!params) return;
    const paramsTemp = cloneDeep(params);
    const keys = Object.keys(params);
    keys.forEach((key) => {
      if (typeof paramsTemp[key] === "object") {
        paramsTemp[key] = this.coverCodeToString(paramsTemp[key]);
      } else {
        if (isCode(paramsTemp[key])) {
          paramsTemp[key] = this.runScript(paramsTemp[key], extra);
        }
      }
    });
    return paramsTemp;
  }

  public createQueryParams(id: string) {
    const config = this.getRealConfig(id);
    const executeActionDTO = {
      actionId: config.id,
      viewMode: false,
      paramProperties: {},
    };
    const { actionConfiguration } = config;
    const bindings: any = [];
    if (actionConfiguration) {
      getActionConfigParams(actionConfiguration, bindings);
    }
    return {
      bindings: [...new Set(bindings)],
      executeActionDTO,
    };
  }

  // 处理pageplug 中的动态参数
  public evaluateActionParams(bindings: string[], executeActionRequest: ExecuteActionRequest) {
    const formData = new FormData();
    if (bindings.length === 0) {
      formData.append("executeActionDTO", JSON.stringify(executeActionRequest));
      return formData;
    }

    const values = bindings.map((code) => this.runScript(code));

    const bindingsMap: Record<string, string> = {};
    const bindingBlob = [];

    for (let i = 0; i < bindings.length; i++) {
      const key = bindings[i];
      let value = values[i];
      if(typeof value === 'undefined') {
        value = '';
      }
      const k = `k${i}`;
      if (Array.isArray(value) && value.length > 0) {
        executeActionRequest.paramProperties[k] = {
          array: [getJsType(value[0])],
        };
      } else {
        executeActionRequest.paramProperties[k] = getJsType(value);
      }

      if (typeof value === "object") {
        value = JSON.stringify(value);
      }

      value = new Blob([value], { type: "text/plain" });
      const realCode = key.substring(2, key.length-2);
      bindingsMap[realCode] = k;
      bindingBlob.push({ name: k, value });
    }

    formData.append("executeActionDTO", JSON.stringify(executeActionRequest));
    formData.append("parameterMap", JSON.stringify(bindingsMap));
    bindingBlob?.forEach((item) => formData.append(item.name, item.value));
    return formData;
  }
  public clearInterval(id: string) {
    if (this.intervalMap[id]) {
      clearInterval(this.intervalMap[id]);
    }
  }
  public isProd() {
    return !isInEditor && (window as any).mlEnv === 'production';
  }
  public getRealConfig(id: string) {
    const config: DataServiceQueryConfigure = this.dataServiceConfigure[id]
    if (config.test && config.prod) {
      if (this.isProd()) {
        return config.prod;
      }
      return config.test;
    }
    return config;
  }

  public isJavascriptQuery(id: string) {
    const config = this.getRealConfig(id);
    const { pluginName } = config;
    return isJsQuery(pluginName);
  }
}
