import { cloneDeep, debounce } from 'lodash-es';
import { getJsType } from '@tmagic/utils'

// 判断是否是代码片段
export const isCode = (str: string): boolean => {
  if (typeof str === "string" && str.trim().startsWith("{{") && str.trim().endsWith("}}")) {
    return true;
  }
  return false;
};


// 判断是否是服务端代码
export const isServiceCode = (str: string): boolean =>  typeof str === "string" && /^\{\{\s*#/.test(str.trim());


// 判断是否包含代码片段， 如sql中
export const hasCode = (str: string): boolean => {
  if (typeof str === "string" && str.includes("{{") && str.includes("}}")) {
    return true;
  }
  return false;
};

export const getCode = (str = "") => {
  str = str.replace(/\n|\r/g, "");
  if (isCode(str)) {
    const res = str.match(/\{\{(.*)\}\}/);
    if (res) {
      return res[1];
    }
  }
  return str;
};

// 获取字符串中的变量，多个变量
export const getParamsInString = (code: string, bindings: string[]) => {
  if (typeof code !== "string") {
    return;
  }
  const len = code.length;
  const starts: number[] = [];
  for(let i = 0; i < len - 1; i++) {
    if(code[i] === '{' && code[i+1] === '{') {
      starts.push(i);
      i += 1;
    }
    else if(code[i] === '}' && code[i+1] === '}' && starts.length > 0) {
      bindings.push(code.substring(starts.pop() as number, i + 2));
      i += 1 ;
    }
  } 
  // 这种 正则在 {{  if(a){}}} 这种情况下误判
  // const res = code.match(/\{\{(.*?)\}\}/g) || [];
  // bindings.push(...res);
};

// 获取变量列表
export const getVarList = (code: string, obj: any) => {
  const varList = Object.keys(obj);
  return varList.filter((key: string) => {
    const regx1 = new RegExp(`\\b${key}\\b`, 'g'); // 匹配 name
    const regx2 = new RegExp(`\\.${key}\\b`, 'g'); // 不匹配 xxx.name
    const regx3 = new RegExp(`\\.\.\.${key}\\b`, 'g'); // 匹配 ...name
    const res1 = code.match(regx1) || [];
    const res2 = code.match(regx2) || [];
    const res3 = code.match(regx3) || [];
    return res1.length > res2.length || res3.length > 1;
  });
 
};

export const generateCodeExeFn = (code: string): Function => {
  const codeFnStr = eval(`
    (function() {
     return (State, Components, Extra) => { 
       ${code}            
     }        
    })() 
   `);
  return eval(codeFnStr);
};

export const runScript = (code: string, state: any, components: any, extra = {}) => {
  if (!isCode(code)) {
    return code;
  }
  const varList1 = getVarList(code, state);
  const varList2 = getVarList(code, components);
  const varList3 = getVarList(code, extra);
  let realCode = getCode(code);
  if (!realCode) {
    return "";
  }
  if(realCode.includes(';') || realCode.includes('return')) {
    realCode = `(()=>{${realCode}})()`
  }
  const codeStr = `const {${varList1.join(",")}} = State; const {${varList2.join(",")}} = Components; const {${varList3.join(
    ","
  )}} = Extra; return (${realCode})`;
  const execFn = generateCodeExeFn(codeStr);
  const data = execFn(state, components, extra);
  return data;
};

// 获取参数中的变量
export const  getActionConfigParams = (params: any, bindings: string[]) => {
  const paramsTemp = cloneDeep(params);
  const keys = Object.keys(params);
  keys.forEach((key) => {
    if (typeof paramsTemp[key] === "object") {
      paramsTemp[key] = getActionConfigParams(paramsTemp[key], bindings);
    } else {
      getParamsInString(paramsTemp[key], bindings);
      if (isCode(paramsTemp[key])) {
        bindings.push(paramsTemp[key]);
      }
    }
  });
}



export const createWorkByCode = (workCode: string) => {
  const workBlob = new Blob([`(${workCode})()`]);
  const url = URL.createObjectURL(workBlob);
  const worker = new Worker(url);
  return worker;
};

function WorkCodeFn() {
  onmessage = (e) => {
    let {fn, code} = e.data;
    let result = code;
    let resultType = 'string';
    try {

      fn = eval(fn);
      result = fn(`(${code})`);
      resultType = typeof result;
      console.log(result, resultType)
      if(resultType === 'function') {
        result = code;
      }
    }
    catch(e) {
      console.log(e.message);
    }
   postMessage({result, resultType});
  };
}


class WorkerCode {
  public worker: Worker;
  public callback: any;
  constructor() {
    this.worker = createWorkByCode(WorkCodeFn.toString());
    const messageFn = debounce((e) => {
      this.callback(e.data);
    },20);
    this.worker.addEventListener("message", messageFn); 
  }

  public postMessage (fn: string, code: any, callback: Function) {
    this.worker.postMessage({fn, code});
    this.callback = callback;
  }

  public async executeCodeInWorker (fn: Function, code: any) {
    return new Promise((res) => {
      this.postMessage(fn.toString(), code, (data: any) => {
        let { result, resultType } = data;
        if(resultType == 'function') {
          result = eval(result);
        }
        res(result);
      })
    })
  }
  public async executeEvalInWorker (code: any) {
    return await this.executeCodeInWorker((v) => eval(v), code);
  }
}

export const workerCode = new WorkerCode();