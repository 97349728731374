import { Component, reactive } from "vue";
import { RouteRecordRaw } from "vue-router";
import { MApp } from "@tmagic/schema";
import Core from "@tmagic/core";

export const generateRoutes = (rootConfig: MApp, components: Record<string, Component>) => {
  const routes: RouteRecordRaw[] = [];
  const { items } = rootConfig;
  if (!items) return routes;
  items.forEach((item) => {
    const { id, type, component_id, version } = item;
    const component = components[`${type}-${component_id}-${version}`];
    if (component) {
      routes.push({
        path: `/${id}`,
        name: String(id),
        component,
        meta: {
          transition: undefined,
        },
      });
    }
  });
  if (routes[0]) {
    routes.unshift({
      path: "/",
      redirect: routes[0].path,
    });
  }
  return routes;
}

export const appendExtendStyled = (app: Core) => {
  let css = ''
  document.querySelector('#_extend_style')?.remove()
  if (app.page) {
    for (const [, { data }] of app.page?.nodes.entries()) {
      if (data.extendStyle && (data.extendStyle as string).trim()) {
        css += data.extendStyle
      }
    }
  }
  const style = window.document.createElement('style')
  style.id = '_extend_style'
  style.type = 'text/css'
  style.innerHTML = css
  window.document.head.appendChild(style)
}

const scriptLoadMap: Record<string, Promise<void>> = {};
export async function asyncLoadScript(url: string) {
  if (!Boolean(scriptLoadMap[url])) {
    scriptLoadMap[url] = new Promise((resolve, reject) => {
      const script = document.createElement('script');
      // script.setAttribute('crossorigin', '');
      script.setAttribute('src', url);
      script.onload = () => {
        resolve();
      };
      script.onerror = reject;
      document.head.append(script);
    });
  }
  return scriptLoadMap[url];
}
