import axios from 'axios';
import * as vue from 'vue';
import { asyncLoadScript } from './index';
import useApp from '../../../packages/ui/src/useApp';
import useDisplay from '../../../packages/ui/src/useDisplay';
import MagicUiComponent from '../../../packages/ui/src/Component.vue';

window.vue = vue;
window.axios = axios.create({});
window.axios.interceptors.response.use((response) => response.data, (error) => Promise.reject(error));
window.asyncLoadScript = asyncLoadScript;
window.useApp = useApp;
window.useDisplay = useDisplay;
window.MagicUiComponent = MagicUiComponent;

;(() => {
  if (typeof (window as any).global === 'undefined') {
    ;(window as any).global = window
  }
})();