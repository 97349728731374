/*
 * Tencent is pleased to support the open source community by making TMagicEditor available.
 *
 * Copyright (C) 2021 THL A29 Limited, a Tencent company.  All rights reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

/**
 * 通用的事件处理
 */

import { isBusinessPage } from '@tmagic/utils';
import { execAnimation } from './animation';
import App from './App';
import Node from './Node';
import { IEventItemConfig, ActionType } from '@tmagic/schema';

export interface EventOption {
  label: string;
  value: string;
  params?: Array<any>;
}

// const COMMON_EVENT_PREFIX = 'magic:common:events:';
// const COMMON_METHOD_PREFIX = 'magic:common:actions:';
const COMMON_EVENT_PREFIX = '';
const COMMON_METHOD_PREFIX = '';
const CommonMethod = {
  // SHOW: 'show',
  // HIDE: 'hide',
  SCROLL_TO_VIEW: 'scrollIntoView',
  SCROLL_TO_TOP: 'scrollToTop',
  ANIMATE_EXEC: 'animateExec',
  ANIMATE_REVERSE_EXEC: 'animateReverseExec',
};

export const DEFAULT_EVENTS: EventOption[] = [
];

export const DEFAULT_METHODS: EventOption[] = [];

if (!isBusinessPage()) {
  // @ts-ignore
  CommonMethod.ANIMATE_EXEC = 'animateExec';
  // @ts-ignore
  CommonMethod.ANIMATE_REVERSE_EXEC = 'animateReverseExec';
  DEFAULT_EVENTS.push({
    label: '点击', value: `${COMMON_EVENT_PREFIX}click`,
  });
  DEFAULT_EVENTS.push({
    label: '动效开始执行', value: `${COMMON_EVENT_PREFIX}animeStart`,
  }, {
    label: '动效执行完成', value: `${COMMON_EVENT_PREFIX}animeEnd`,
  });
  DEFAULT_METHODS.push({
    label: '执行动效', value: `${COMMON_METHOD_PREFIX}${CommonMethod.ANIMATE_EXEC}`,
  }, {
    label: '反向执行动效', value: `${COMMON_METHOD_PREFIX}${CommonMethod.ANIMATE_REVERSE_EXEC}`,
  });
}



export const getCommonEventName = (commonEventName: string) => {
  if (commonEventName.startsWith(COMMON_EVENT_PREFIX)) return commonEventName;
  return `${COMMON_EVENT_PREFIX}${commonEventName}`;
};

export const isCommonMethod = (methodName: string) => methodName.startsWith(COMMON_METHOD_PREFIX) && Object.values(CommonMethod).includes(methodName);

// 点击在组件内的某个元素上，需要向上寻找到当前组件
const getDirectComponent = (element: HTMLElement | null, app: App): Node | Boolean => {
  if (!element) {
    return false;
  }

  if (!element.id) {
    return getDirectComponent(element.parentElement, app);
  }

  const node = app.page?.getNode(element.id);
  if (!node) {
    return false;
  }

  return node;
};

const commonClickEventHandler = (app: App, eventName: string, e: any) => {
  const node = getDirectComponent(e.target, app);

  if (node) {
    (node as Node).currentEventTarget = e.target
    app.emit(getCommonEventName(eventName), node);
  }
};

export const bindCommonEventListener = (app: App) => {
  if (isBusinessPage()) {
    return;
  }
  window.document.body.addEventListener('click', (e: any) => {
    commonClickEventHandler(app, 'click', e);
  });

  window.document.body.addEventListener(
    'click',
    (e: any) => {
      commonClickEventHandler(app, 'click:capture', e);
    },
    true,
  );
};

export const triggerCommonMethod = (methodName: string, node: Node) => {
  const { instance } = node;

  switch (methodName.replace(COMMON_METHOD_PREFIX, '')) {
    // case CommonMethod.SHOW:
    //   // instance.show();
    //   node.data.display = true;
    //   break;

    // case CommonMethod.HIDE:
    //   // instance.hide();
    //   node.data.display = false;
    //   break;

    case CommonMethod.SCROLL_TO_VIEW:
      instance.$el.scrollIntoView({ behavior: 'smooth' });
      break;

    case CommonMethod.SCROLL_TO_TOP:
      window.scrollTo({ top: 0, behavior: 'smooth' });
      break;

    case CommonMethod.ANIMATE_EXEC:
      execAnimation(node);
      break;

    case CommonMethod.ANIMATE_REVERSE_EXEC:
      execAnimation(node, true);
      break;

    default:
      break;
  }
};

export const eventHandler = (app: App, eventConfig: IEventItemConfig, fromCpt: Node, parentsExtraDataForChildren: any, args: any[]) => {
  if (!app.page) throw new Error('当前没有页面');
  const { actionType , condition} = eventConfig;

  // 没有达到执行条件
  if(condition && !app.runScript(condition)) {
    return ;
  }
  if (actionType === ActionType.runQuery ) {
    app.launchService(eventConfig.queryId as string)
  }
  else if(actionType === ActionType.runComponent) {
    const { method, componentId, methodParams } = eventConfig;
    app.runComponent(componentId as string, method as string, methodParams, parentsExtraDataForChildren);
  }
  else if(actionType === ActionType.setTempState) {
    const { code, queryId } = eventConfig;
    app.setTempState(queryId as string, code as string, parentsExtraDataForChildren);
  }
  else if(actionType === ActionType.runJavascript) {
    const { code } = eventConfig;
    app.runScript(code as string, parentsExtraDataForChildren);
  }
  else if(actionType === ActionType.toast) {
    app.toast(eventConfig as any, parentsExtraDataForChildren);
  }
  else if(actionType === ActionType.openLink) {
    const { jumpUrl, _blank } = eventConfig;
    app.openLink(jumpUrl as string, _blank as boolean, parentsExtraDataForChildren);
  }
  else if(actionType === ActionType.openApp) {
    const { appId, queryParameters, _blank } = eventConfig;
    app.openApp(appId, queryParameters, _blank as boolean, parentsExtraDataForChildren);
  }
  else if(actionType === ActionType.download) {
    const { fileType, fileName } = eventConfig;
    const fileData = app.runScript(eventConfig.fileData);
    app.download({ fileType, fileData, fileName });
  }
}