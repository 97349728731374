/*
 * Tencent is pleased to support the open source community by making TMagicEditor available.
 *
 * Copyright (C) 2021 THL A29 Limited, a Tencent company.  All rights reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import '../utils/polyfills';
import { createApp, defineAsyncComponent, reactive } from 'vue';
import { createRouter, createWebHashHistory } from 'vue-router';

import Core from '@tmagic/core';
import { getUrlParam } from '@tmagic/utils';

// import components from '../.tmagic/async-comp-entry';
// import plugins from '../.tmagic/plugin-entry';

// import request from './utils/request';
import AppComponent from './App.vue';
// import { getLocalConfig } from './utils';
// @ts-ignore
import importComponents from 'importComponents';
// @ts-ignore
import importPlugins from 'importPlugins';
import { runAnimation } from '../plugins/runAnimation';
import { calcInterpolationExpression } from '../plugins/runInterpolationExpression';
import { generateRoutes, appendExtendStyled } from '../utils';
import { isBusinessPage } from '@tmagic/utils';

Promise.all([importPlugins(), importComponents()]).then(async ([plugins, components]) => {
  if (isBusinessPage()) {
    await import('../utils/authCheck').then(({ authCheck }) => {
      return authCheck(window.magicDSL[0].pageId);
    });
  }
  plugins[`basic:calcInterpolationExpression`] = calcInterpolationExpression;
  if (!isBusinessPage()) {
    plugins[`basic:runAnimation`] = runAnimation;
  }
  await Core.intsallPlugins(plugins, Object.values(window.magicDSL?.[0]?.plugins || {}))
  const magicApp = createApp(AppComponent);

  if (isBusinessPage()) {
    await import('../utils/importElementPlus').then(({ importElementPlus }) => {
      importElementPlus(magicApp);
    })
  }

  // magicApp.use(request);

  Object.entries(components).forEach(([type, component]: [string, any]) => {
    magicApp.component(
      `magic-ui-${type}`,
      component,
    );
  });

  // Object.values(plugins).forEach((plugin: any) => {
  //   magicApp.use(plugin);
  // });

  const designWidth = document.documentElement.getBoundingClientRect().width;

  const rootConfig = reactive(window.magicDSL[0]);

  const app = new Core({
    designWidth: window.magicDSL[0].stageRect.width,
    // config: ((getUrlParam('localPreview') ? getLocalConfig() : window.magicDSL) || [])[0] || {},
    config: rootConfig,
    curPage: getUrlParam('page'),
  });

  window.appInstance = app;
  // @ts-ignore
  app.vueApp = magicApp;

  const router = createRouter({
    history: createWebHashHistory(),
    routes: generateRoutes(rootConfig, components)
  })
  // @ts-ignore
  app.router = router;
  magicApp.use(router);

  if (app.page) {
    const { title, description, keywords, icon } = app.page.data
    document.title = title
    const metaEl = document.createElement('meta')
    metaEl.setAttribute('name', 'description')
    metaEl.setAttribute('content', description)
    document.head.append(metaEl)
    const metaElkeyWord = document.createElement('meta')
    metaElkeyWord.setAttribute('name', 'keywords')
    metaElkeyWord.setAttribute('content', keywords)
    document.head.append(metaElkeyWord)
    appendExtendStyled(app);
    if(icon) {
      const iconEl: any = document.querySelector('[rel="icon"]') || {};
      iconEl.href = icon;
    }
  }

  app.dataService.state = reactive(app.dataService.state);

  await app.runPluginsLifecycleFunction('appCreated', app);

  magicApp.config.globalProperties.app = app;
  magicApp.provide('app', app);

  magicApp.mount('#app');

  await app.runPluginsLifecycleFunction('appMounted', app);

});

