import { getDataCoreApiPrefix } from '@tmagic/utils';
import axios, { AxiosRequestConfig } from 'axios';

const windowAxios = window.axios;

const cancelTokens: any = {};
interface DataCoreResponse {
  responseMeta: {
    status: number;
    success: boolean;
    error?: {
      code: number;
      errorType: string;
      message: string;
    }
  }
  data: {
    body: any;
  }
}

export default class DataCoreRequest {
  public axiosInstance = windowAxios;
  public baseUrl = getDataCoreApiPrefix();
  public authHeaders: any = {};
  // public actionUrl = '/v1/data-source/actions';
  constructor() {
    this.axiosInstance.defaults.baseURL = this.baseUrl;

    this.setAuthHeaders()
    window.addEventListener('dataCoreAuthHeadersChange', this.setAuthHeaders)
  }
  public setAuthHeaders() {
    const authHeaders = sessionStorage.getItem('dataCoreAuthHeaders')
    if (authHeaders) {
      this.authHeaders = JSON.parse(authHeaders)
    }
  }
  public async request<T = any>(config: AxiosRequestConfig): Promise<DataCoreResponse> {
    config.headers = {
      ...this.authHeaders,
      ...config.headers,
      // 'X-SESSION': 'e0628e84-6cba-485c-8950-81a2aa43844e'
    };
    const res = await this.axiosInstance<any, DataCoreResponse>(config).catch((error) => {
      throw error;
    });

    return res;
  }


  public async runQuery(queryConfig: any, formData: FormData) {
    const { cancelPre, id } = queryConfig;
    if (cancelPre && cancelTokens[id]) {
      cancelTokens[id]();
    }
    let res: DataCoreResponse = {} as DataCoreResponse;
    try {
      res = await this.request({
        url: `/api/v1/actions/execute`,
        method: 'post',
        headers: {
          'content-type': 'multipart/form-data'
        },
        data: formData,
        cancelToken: new axios.CancelToken( (c) => {
          cancelTokens[id] = c;
        })
      });
    }
    catch(e: any) {

    }
    if (res?.responseMeta?.success) {
      return res?.data.body
    }
    else {
      (window as any).appInstance.toast({message: res?.responseMeta.error?.message, type: 'error', duration: 2000})
    }
  }

  // 直接发起请求
  public async runQueryDirect(requestConfig: any) {
    return windowAxios(requestConfig);
  }

}
