/*
 * Tencent is pleased to support the open source community by making TMagicEditor available.
 *
 * Copyright (C) 2021 THL A29 Limited, a Tencent company.  All rights reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { EventEmitter } from 'events';

import { CodeBlockDSL, EventItemConfig, Id, MApp, MPage, KeyValuePair, DataServiceQueryConfigure, DownLoadFileType } from '@tmagic/schema';
import { IEventItemConfig, DownloadAction } from '@tmagic/schema';
import { reactive } from 'vue';
import Env from './Env';
import { bindCommonEventListener, isCommonMethod, triggerCommonMethod, eventHandler } from './events';
import type Node from './Node';
import Page from './Page';
import { intsallPlugins, runPluginsLifecycleFunction, checkPluginsInit } from './plugin';
import { fillBackgroundImage, isNumber, style2Obj, toast } from './utils';
import DataService from './dataService';
import {debounce, throttle} from 'lodash-es';
import { isBusinessPage } from '@tmagic/utils';
import { download } from './downLoad';

interface AppOptionsConfig {
  ua?: string;
  config?: MApp;
  platform?: 'editor' | 'mobile' | 'tv' | 'pc';
  jsEngine?: 'browser' | 'hippy';
  designWidth?: number;
  curPage?: Id;
  transformStyle?: (style: Record<string, any>) => Record<string, any>;
  mode?: string,
}

interface EventCache {
  eventConfig: EventItemConfig;
  fromCpt: any;
  args: any[];
}

class App extends EventEmitter {
  static intsallPlugins = intsallPlugins;
  public mode!: string;
  public env;
  public codeDsl: CodeBlockDSL | undefined;
  public pages = new Map<Id, Page>();
  public vueApp: any;
  public page: Page | undefined;

  public platform = 'mobile';
  public jsEngine = 'browser';
  public designWidth = 375;
  public dataService: any = new DataService(window.magicDSL?.[0]?.dataServiceConfigure);

  public components = new Map();

  public eventQueueMap: Record<string, EventCache[]> = {};

  public runPluginsLifecycleFunction = runPluginsLifecycleFunction;
  public checkPluginsInit = checkPluginsInit;

  public componentInsExposeds: Record<string, any> = reactive({});

  constructor(options: AppOptionsConfig) {
    super();
    this.setMaxListeners(Infinity);
    this.intsComponentInstancesExposed(options.config?.items);
    this.env = new Env(options.ua);
    options.mode && (this.mode = options.mode);
    // 代码块描述内容在dsl codeBlocks字段
    this.codeDsl = options.config?.codeBlocks;
    options.platform && (this.platform = options.platform);
    options.jsEngine && (this.jsEngine = options.jsEngine);
    options.designWidth && (this.designWidth = options.designWidth);
    this.dataService.app = this;

    // 根据屏幕大小计算出跟节点的font-size，用于rem样式的适配
    if (this.platform === 'mobile' || this.platform === 'editor') {
      const calcFontsize = () => {
        const { width } = document.documentElement.getBoundingClientRect();
        const fontSize = width / (this.designWidth / 100);
        document.documentElement.style.fontSize = `${fontSize}px`;
      };

      calcFontsize();

      document.body.style.fontSize = '14px';

      globalThis.addEventListener('resize', calcFontsize);
    }

    if (options.transformStyle) {
      this.transformStyle = options.transformStyle;
    }

    options.config && this.setConfig(options.config, options.curPage);
    bindCommonEventListener(this);

  }

  // 初始化组件数据，默认为{},需要
  intsComponentInstancesExposed(items: any) {
    // 只初始化一次
    if(Object.keys(this.componentInsExposeds).length > 0) {
      return;
    }
    if(!items) return;
    const searchItems = (items: any) => {
      items.forEach((item: any) => {
        this.componentInsExposeds[item.id] = {};
        if(item.items) {
          searchItems(item.items);
        }
      })
    }
    searchItems(items);
  }

  /**
   * 将dsl中的style配置转换成css，主要是将数子转成rem为单位的样式值，例如100将被转换成1rem
   * @param style Object
   * @returns Object
   */
  public transformStyle(style: Record<string, any> | string) {
    if (!style) {
      return {};
    }

    let styleObj: Record<string, any> = {};
    const results: Record<string, any> = {};

    if (typeof style === 'string') {
      styleObj = style2Obj(style);
    } else {
      styleObj = { ...style };
    }

    const whiteList = ['zIndex', 'opacity', 'fontWeight'];
    Object.entries(styleObj).forEach(([key, value]) => {
      if (key === 'backgroundImage') {
        value && (results[key] = fillBackgroundImage(value));
      } else if (key === 'transform' && typeof value !== 'string') {
        const values = Object.entries(value as Record<string, string>)
          .map(([transformKey, transformValue]) => {
            if (!transformValue.trim()) return '';
            if (transformKey === 'rotate' && isNumber(transformValue)) {
              transformValue = `${transformValue}deg`;
            }
            return `${transformKey}(${transformValue})`;
          })
          .join(' ');
        results[key] = !values.trim() ? 'none' : values;
      } else if (!whiteList.includes(key) && value && /^[-]?[0-9]*[.]?[0-9]*$/.test(value)) {
        results[key] = `${value / 100}rem`;
      } else {
        results[key] = value;
      }
    });

    return results;
  }

  /**
   * 设置dsl
   * @param config dsl跟节点
   * @param curPage 当前页面id
   */
  public setConfig(config: MApp, curPage?: Id) {
    this.codeDsl = config.codeBlocks;
    this.pages = new Map();
    config.items?.forEach((page: MPage) => {
      this.pages.set(
        page.id,
        new Page({
          config: page,
          app: this,
        }),
      );
    });

    this.setPage(curPage || this.page?.data?.id);
  }

  public updatePageNodes(config: MApp) {
    const pageConfig = config.items.find((page: MPage) => page.id === this.page?.data?.id)
    if (pageConfig) {
      this.page?.updateNodes(pageConfig);
    }
  }

  public setPage(id?: Id) {
    let page;

    if (id) {
      page = this.pages.get(id);
    }

    if (!page) {
      page = this.pages.get(this.pages.keys().next().value);
    }

    if (this.page === page) return;
    this.page = page;

    if (this.platform !== 'magic') {
      this.bindEvents();
    }
  }
  public registerComponent(type: string, Component: any) {
    this.components.set(type, Component);
  }

  public unregisterComponent(type: string) {
    this.components.delete(type);
  }

  public resolveComponent(type: string) {
    return this.components.get(type);
  }

  public bindEvents() {
    if (!this.page) return;

    this.removeAllListeners();

    for (const [, value] of this.page.nodes) {
      value.events?.forEach?.((event) => this.bindEvent(event, `${value.data.id}`));
    }

    Object.values(this.dataService.dataServiceConfigure).forEach(config => {
      const extraConfiguration = config.extraConfiguration || config.test?.extraConfiguration;
      extraConfiguration?.eventList?.forEach?.((event) => this.bindEvent(event, `${config.id}`))
    });
  }

  public bindEvent(event: IEventItemConfig, id: string) {
    const { name , debounceThrottle, debounceThrottleTime} = event;
    const baseFn =  (fromCpt: Node, ...args: any[]) => {
      this.eventHandler(event, fromCpt, args);
    }
    let executeFn = baseFn;
    if(debounceThrottleTime) {
      if(debounceThrottle == 'debounce') {
        executeFn = debounce(baseFn, debounceThrottleTime);
      }
      else {
        executeFn = throttle(baseFn, debounceThrottleTime);
      }
    }

    this.on(`${name}_${id}`, executeFn);
    return () => {
      this.off(`${name}_${id}`, executeFn);
    }
  }

  public emit(name: string | symbol, node: any, ...args: any[]): boolean {
    if (node?.data?.id) {
      return super.emit(`${String(name)}_${node.data.id}`, node, ...args);
    }
    return super.emit(name, node, ...args);
  }

  public eventHandler(eventConfig: IEventItemConfig, fromCpt: any, args: any[]) {
    if (!this.page) throw new Error('当前没有页面');
    const parentsExtraDataForChildren = args?.[args.length - 1]?.parentsExtraDataForChildren;
    return eventHandler(this, eventConfig, fromCpt, parentsExtraDataForChildren, args);
  }

  // 执行数据服务
  public launchService(id: string) {
    this.dataService.launchService(id)
  }

  // 执行组件的方法
  public runComponent(componentId: string, method: string, args?: any, extra?: any) {
    const toNode = this.page?.getNode(componentId as string);
    if (!toNode || !method) return;
    if (isCommonMethod(method)) {
      return triggerCommonMethod(method, toNode);
    }
    if (toNode.instance) {
      if (typeof this.componentInsExposeds[componentId]?.[method] === 'function') {
        let params = [];
        if(args && Array.isArray(args)) {
          params = this.dataService.coverCodeToString(args, extra);
        }
       return this.componentInsExposeds[componentId][method](...params);
      }
    }
  }

  // 设置临时状态
  public setTempState(queryId: string, code: string, extra?: any) {
    if(!queryId) return;
    this.dataService.setTempState(queryId, code, extra);
  }

  // 全局提示
  public toast(obj: { message: string, type: string , duration: number }, extra?: any) {
    const message = this.runScript(obj.message, extra);
    if(!isBusinessPage()) {
      toast(message, obj.duration > 1000 ? obj.duration: 2000);
      return;
    }
    this.vueApp.config?.globalProperties.$message({
      type: obj.type,
      message,
      duration: obj.duration > 1000 ? obj.duration: 1000
    })
  }
  // 确认弹框 
  public async confirm(msg: string) {
    if(isBusinessPage()) {
      return this.vueApp.config?.globalProperties.$confirm(msg);
    }
    // todo 优化 c端临时使用window.confirm
    return window.confirm(msg);
  }

  // 执行javascript脚本
  public runScript(code: string, extra?: any): any {
    return this.dataService.coverStringCodeToString(code, extra);
  }

  // 打开连接
  public openLink(jumpUrl: string, _blank: boolean, extra?: any) {
    if(!jumpUrl) return;
    jumpUrl = this.runScript(jumpUrl, extra);
    if(_blank) {
      window.open(jumpUrl);
    }
    else {
     window.location.href= jumpUrl;
    }
  }

  // 打开应用
  public openApp(id: string, queryParameters: KeyValuePair[], _blank: boolean, extra?: any) {
    if(!id) return;
    queryParameters = this.dataService.coverCodeToString(queryParameters, extra);
    const search = new URLSearchParams(location.search);
    search.set('page', id);
    queryParameters?.forEach((item) => {
      if(item.key) {
        search.set(item.key, item.value);
      }
    })
    const url = `${location.origin}${location.pathname}?${search.toString()}#/${id}`;
    this.openLink(url, _blank, extra);
  }

  public download(downConfig: DownloadAction) {
    download(downConfig);
  }

  public destroy() {
    this.removeAllListeners();
    this.pages.clear();
  }

  private addEventToMap(event: EventCache) {
    if (typeof event.eventConfig.to === 'object') return
    if (this.eventQueueMap[event.eventConfig.to]) {
      this.eventQueueMap[event.eventConfig.to].push(event);
    } else {
      this.eventQueueMap[event.eventConfig.to] = [event];
    }
  }
}

export default App;
