import { MComponent, MContainer } from '@tmagic/schema';
import { asyncLoadJs, getUrlParam } from '@tmagic/utils';

const importComponentsPromise = (async () => {
  const pages = window.magicDSL[0]?.items
  // const pageId = getUrlParam('page')
  
  // const page = pageId ? pages.find(item => item.id === pageId) : pages[0]
  const componentsMap = new Map<string, Set<string>>()
  function findComponentType({ type, version, component_id, items, dependencies }: MComponent | MContainer) {
    if (version) {
      const versionSet = componentsMap.get(`${type}-${component_id}`) || new Set()
      versionSet.add(version)
      componentsMap.set(`${type}-${component_id}`, versionSet)
    }
    if (dependencies) {
      dependencies.forEach(({ type, component_id, version }: any) => {
        if (version) {
          const versionSet = componentsMap.get(`${type}-${component_id}`) || new Set()
          versionSet.add(version)
          componentsMap.set(`${type}-${component_id}`, versionSet)
        }
      })
    }
    if (items) {
      items.forEach(findComponentType)
    }
  }
  pages.forEach((page) => {
    findComponentType(page)
  });
  const componentsAsync: Record<string, any> = {}
  await Promise.all([...componentsMap].map(([type_id, versionSet]) => Promise.all([...versionSet].map(async (version) => {
    await asyncLoadJs(`${window.assetsPrefix || 'https://web-static.mini1.cn'}/lego/svc-files/components/production/${type_id}/${version}/component.js`)
    // @ts-ignore
    componentsAsync[`${type_id}-${version}`] = window[`tmagic-component-${type_id}-${version.replace(/\./g, '_')}`]
  }))))
  return componentsAsync
})()

export default async function importComponents() {
  return importComponentsPromise
}