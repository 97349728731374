<template>
  <router-view v-slot="{ route, Component }">
    <transition :duration="{
      enter: 0,
      leave: (route.meta.leaveDuration as number) || 0,
    }">
      <component
        :is="Component"
        v-if="Component"
        :config="pagesConfig[route.name as string]"
        :key="route.path"
      ></component>
    </transition>
  </router-view>
</template>

<script lang="ts">
import { defineComponent, inject, reactive, watch } from 'vue';

import Core from '@tmagic/core';
import { MPage } from '@tmagic/schema';
import { useRoute } from 'vue-router';

export default defineComponent({
  name: 'App',

  setup() {
    const app = inject<Core>('app')!;

    const pagesConfig = reactive<Record<string, MPage>>({});
    app.pages.forEach((page, key) => {
      pagesConfig[key] = page.data as MPage;
    });

    const route = useRoute()
    watch(
      () => route.path,
      () => {
        app.setPage(route.name as string);
        app.runPluginsLifecycleFunction('pageChange', app)
      },
      { immediate: true },
    );

    return {
      pagesConfig,
    };
  },
});
</script>
