/*
 * Tencent is pleased to support the open source community by making TMagicEditor available.
 *
 * Copyright (C) 2021 THL A29 Limited, a Tencent company.  All rights reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { EventEmitter } from 'events';

import { isEmpty } from 'lodash-es';

import type { IEventItemConfig, MComponent, MContainer, MPage } from '@tmagic/schema';

import type App from './App';
import type Page from './Page';
import Store from './Store';

interface NodeOptions {
  config: MComponent | MContainer;
  page?: Page;
  parent?: Node;
  app: App;
}
class Node extends EventEmitter {
  public data: MComponent | MContainer | MPage;
  public style?: {
    [key: string]: any;
  };
  public events?: IEventItemConfig[];
  public extendStyle?: string;
  public instance?: any;
  public page?: Page;
  public parent?: Node;
  public app: App;
  public store = new Store();
  public currentEventTarget?: Element;
  public extraDataForChildrenForCodeMirror?: any;

  constructor(options: NodeOptions) {
    super();

    this.page = options.page;
    this.parent = options.parent;
    this.app = options.app;
    const { events } = options.config;
    this.data = options.config;
    this.events = events;
    this.listenLifeSafe();

    this.on('destroy', () => {
      this.instance = null;
      if (typeof this.data.destroy === 'function') {
        this.data.destroy(this);
      }
      delete this.app.componentInsExposeds[this.data.id];
      this.listenLifeSafe();
    });
  }

  private listenLifeSafe() {
    this.on('created', async (instance: any) => {
      this.instance = instance;
      if (typeof this.data.created === 'function') {
        this.data.created(this);
      }
    });

    this.on('mounted', async (instance: any) => {
      this.instance = instance;

      const eventConfigQueue = this.app.eventQueueMap[this.data.id] || [];
      this.app.componentInsExposeds[this.data.id] = instance._.exposed;
      for (let eventConfig = eventConfigQueue.shift(); eventConfig; eventConfig = eventConfigQueue.shift()) {
        this.app.eventHandler(eventConfig.eventConfig, eventConfig.fromCpt, eventConfig.args);
      }

      if (typeof this.data.mounted === 'function') {
        this.data.mounted(this);
      }
    });
  }

  private async runCodeBlock(hook: string) {
    if (!Array.isArray(this.data[hook]) || !this.app.codeDsl || isEmpty(this.app?.codeDsl)) return;
    for (const codeId of this.data[hook]) {
      if (this.app.codeDsl[codeId] && typeof this.app?.codeDsl[codeId]?.content === 'function') {
        await this.app.codeDsl[codeId].content(this);
      }
    }
  }

  public getParentsExtraDataForCodeMirror() {
    let parent = this.parent;
    let extraData: any;
    while (parent) {
      if (parent.extraDataForChildrenForCodeMirror) {
        extraData = parent.extraDataForChildrenForCodeMirror;
        break;
      }
      parent = parent.parent;
    }
    return extraData;
  }
}

export default Node;
