export interface ExecuteActionRequest {
  actionId: string;
  viewMode: boolean;
  paramProperties: Record<string, string | Record<string, string[]>>;
}

enum PaginationType {
  NONE = 'NONE',
  PAGE_NO = 'PAGE_NO',
  URL = 'URL'
}
export interface KeyValuePair {
  key?: string;
  value?: unknown;
}

export interface ActionConfig {
  timeoutInMillisecond?: number;
  paginationType?: PaginationType;
  formData?: Record<string, unknown>;
  pluginSpecifiedTemplates?: KeyValuePair[];
  path?: string;
  queryParameters?: KeyValuePair[];
  body?: string;
}
export interface ExeQueryAction {
  queryId?: string;
}
export interface CtrComponentAction {
  componentId?: string;
  method?: string;
  methodParams?: Array<string>;
}

export interface JumpUrlAction {
  jumpUrl?: string;
  _blank?: Boolean;
}
export interface ShowToastAction {
  message?: string;
  messageType?: string;
  duration?: number;
}

export interface ExeScriptAction {
  code?: string;
}
export interface OpenApp {
  appId: string;
  queryParameters: KeyValuePair[];
  _blank?: Boolean;
}

export interface DownloadAction {
  fileType: DownLoadFileType;
  fileData: any;
  fileName: string;

}

export interface IEventItemConfig extends ExeScriptAction , ShowToastAction , JumpUrlAction , CtrComponentAction , ExeQueryAction, OpenApp, DownloadAction {
  name: string;
  actionType: ActionType;
  debounceThrottle: 'throttle' | 'debounce';
  debounceThrottleTime?: number;
  condition?: string;
}

export interface ServiceConfigure {
  id: number;
  name: string;
  type: 'folder' | 'dataSource'; // 类型
  list?: Array<DataServiceQueryConfigure | ServiceConfigure>; // 数据源下的查询列表
  pluginName?: PagePlugPluginName; // 数据源插件名
  expand?: boolean; // 是否展开
}


export interface DataServiceQueryConfigure {
  test?: DataServiceQueryConfigure;
  prod?:DataServiceQueryConfigure;
  validName: string;
  action_group_name: string;
  describe: string; // 描述
  id: string; // id
  actionConfiguration?: ActionConfig;
  datasource?: IDataSource;
  name: string; // 名字
  organizationId: string;
  data_source_id?: string;
  pageId: string;
  collectionId?: string;
  pluginId: string;
  executeOnLoad: boolean;
  dynamicBindingPathList: any [];
  isValid: boolean;
  invalids: string[];
  jsonPathKeys: string[];
  cacheResponse: string;
  confirmBeforeExecute?: boolean;
  eventData?: any;
  messages: string[];
  pluginType: PagePlugPluginType;
  pluginName: PagePlugPluginName;
  expand?: boolean;
  extraConfiguration: {               // 执行配置，存在dsl
    executeType: ExecuteType; // 是否自动执行,
    url: string;
    eventList: Array<IEventItemConfig>; // 执行成功后执行xxx  ，默认没有
    showConfirm?: boolean;
    confirmMessage?: string;
    condition?: string;
    cancelPre?: boolean;
    duration?: number;
    isErrorNotic?: boolean; // 执行失败是否通 
    loop?: boolean;
    interval?: number;
    isSuccessNotic?: boolean;
    successMessage?: string;
    code?: string;
    prodCode?: string;
    testCode?: string;
  }
}

export enum DownLoadFileType {
  TXT = 'TXT',
  JSON ='JSON',
  CSV = 'CSV',
  Excel = 'xlsx',
  URL = 'URL',
  Base64 ='Base64'
}

export enum PagePlugPluginType {
  API = 'API',
  DB = 'DB',
  SAAS = 'SAAS',
  JS = 'JS',
  REMOTE = 'REMOTE',
  STATE = 'STATE'
}


export enum PagePlugPluginName {
  MySql = 'MySQL',
  Redis = 'Redis',
  MongoDB = 'MongoDB',
  Api = 'REST API',
  JS = 'JS',
  Elasticsearch = 'Elasticsearch',
  State = 'State',
  Env = 'Env',
  Reactive = 'Reactive',
  FrontApi = 'FrontApi'
}

export enum ExecuteType {
  inputChange = 1,
  pageLoad = 2,
  manual = 3
}


export interface IDataSource {
  name: string;
  pluginId: string;
  workspaceId: string;
  id: string;
  data_source_id: string;
  data: any; 
  url?: string;
  pluginType: PagePlugPluginType;
  pluginName: string;
  datasourceConfiguration: any;
  list?: Array<DataServiceQueryConfigure>;
}

export enum EventName {
  querySuccess = 'querySuccess',
  queryError = 'queryError'
}

export enum ActionType {
  runQuery = 1,
  runComponent = 2,
  runJavascript = 3,
  setTempState = 4,
  openApp = 5,
  toast = 6,
  openLink = 7,
  copy = 8,
  download = 9
}
export interface IEventConfig {
  eventName?: string;
  event?: string;
  actionType: ActionType;
  componentId?: string;
  query?: string;
  method?: string;
  methodParams?: Array<string>;
  params?: string;
  condition?: string;
  code?: string;
}

export enum EHttpMedthod {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  DELETE = 'DELETE',
  PATCH = 'PATCH',
  HEAD = 'HEAD',
  OPTIONS = 'OPTIONS',
  TRACE = 'TRACE'
}

export enum ESHttpMethod {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  DELETE = 'DELETE'
}

export enum EBodyType {
  None = 'None',
  JSON = 'application/json',
  xWwwFormUrlencoded = 'application/x-www-form-urlencoded',
  Raw = 'text/plain',
  FormData = 'multipart/form-data'
}

export enum EValueType {
  File = 'FILE',
  Text = 'TEXT',
  Array = 'ARRAY'
}

export interface PagePlugInfo {
  pagePlugApplicationId: string;
  pagePlugPageId: string;
  pagePlugWorkSpaceId: string;
}

export function isJsQuery(pluginName: PagePlugPluginName | string): boolean {
  return pluginName === PagePlugPluginName.State || pluginName === PagePlugPluginName.JS || pluginName === PagePlugPluginName.Env || pluginName === PagePlugPluginName.Reactive
}

export function isEnvQuery(pluginName: PagePlugPluginName | string): boolean {
  return pluginName === PagePlugPluginName.Env
}

export function isFrontQuery(pluginName: PagePlugPluginName | string): boolean {
  return isJsQuery(pluginName) || pluginName === PagePlugPluginName.FrontApi
}

export function isDefaultAutoExe(pluginName: PagePlugPluginName | string): boolean {
  return pluginName === PagePlugPluginName.Env || pluginName === PagePlugPluginName.State
}

export function isReactive(pluginName: PagePlugPluginName | string): boolean {
  return pluginName === PagePlugPluginName.Reactive
}