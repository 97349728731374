/*
 * Tencent is pleased to support the open source community by making TMagicEditor available.
 *
 * Copyright (C) 2021 THL A29 Limited, a Tencent company.  All rights reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { getCurrentInstance, inject, onMounted, onUnmounted, toRaw } from 'vue';

import Core from '@tmagic/core';

export default (props: any) => {
  const app: Core | undefined = inject('app');
  const node = app?.page?.getNode(props.config.id);

  const vm = getCurrentInstance()?.proxy;

  app?.runPluginsLifecycleFunction('componentSetup', app, vm, props.config);

  node?.emit('created', vm);

  onMounted(() => {
    node?.emit('mounted', vm);
  });

  onUnmounted(() => {
    node?.emit('destroy', vm);
  });

  const parentsExtraDataForChildren = inject<any>('parentsExtraDataForChildren', undefined);
  // 用proxy包裹app的emit方法，不影响其他方法和属性
  const appProxy = new Proxy(app!, {
    get(target, prop) {
      if (prop === 'emit') {
        return (name: string | symbol, node: any, ...args: any[]) => {
          app?.emit(name, node, ...args, { parentsExtraDataForChildren });
        };
      }
      return Reflect.get(target, prop);
    },
  });

  return appProxy as Core;
};
