<template>
  <component
    :is="tagName"
    :id="config.id"
    :class="`magic-ui-component${config.className ? ` ${config.className}` : ''}`"
    :style="style"
    :config="config"
  ></component>
</template>

<script lang="ts">
import { computed, defineComponent, getCurrentInstance, inject, provide, ref, watch } from 'vue';

import Core from '@tmagic/core';
import { toLine } from '@tmagic/utils';

export default defineComponent({
  props: {
    config: {
      type: Object,
      default: () => ({}),
    },
  },

  setup(props) {
    const vm = getCurrentInstance()?.proxy;
    const app: Core | undefined = inject('app');

    provide('hoc', vm);

    // const displayBySelect = ref(false);
    // if (app?.mode === 'playground') {
    //   app?.on('editor:select', (info, path) => {
    //     if (path.find((node: MNode) => node.id === props.config.id)) {
    //       displayBySelect.value = true;
    //     } else {
    //       displayBySelect.value = false;
    //     }
    //     console.log('editor:select', info, path, displayBySelect.value);
    //   });
    // }

    // const node = app?.page?.getNode(props.config.id);
    // watch(
    //   () => props.config.display,
    //   (display) => {
    //     console.log(`组件${props.config.id}的可见性变为${display}， 并广播事件`);
    //     if (display) {
    //       app?.emit('display', node);
    //     } else {
    //       app?.emit('hidden', node);
    //     }
    //   }
    // );

    return {
      tagName: computed(() => `magic-ui-${toLine(props.config.type)}-${props.config.component_id}-${props.config.version}`),
      style: computed(() => app?.transformStyle(props.config.style)),

      // display: () => {
      //   const displayCfg = props.config?.display;

      //   if (typeof displayCfg === 'function') {
      //     return displayCfg(app);
      //   }
      //   return displayCfg !== false;
      // },
      // displayBySelect,
    };
  },
});
</script>
